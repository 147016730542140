export const LABELS = {
  DASHCAMERA: {
    header: "Интегрированное видео здесь.",
    header2:
      "Osrit Dashcam помогает вам улучшить поведение водителей и снизить риски для вашего бизнеса, предоставляя визуальный контекст суровых условий вождения.",
    header3: "Чтобы узнать больше, посетите сайт",
    header3Link: "www.truckdashcamera.com",
  },
  OSRIT: {
    header: "Программное обеспечение для облачных грузоперевозок | TMS.",
    header2:
      "Программное обеспечение Osrit Cloud помогает вам легко отправлять FTL или LTL грузы, клиентов, брокеров, водителей, расчет заработной платы, выставление счетов и т. д. из одного места!",
    header3: "Чтобы узнать больше, посетите сайт",
    header3Link: "www.osrit.com",
  },
  SIGN_IN_FORM: {
    email: "Электронная почта",
    password: "Пароль",
    logInBtn: "Авторизоваться",
    logInLoading: "Загрузка...",
    forgetPwd: "Забыть пароль",
    required: "Необходимый",
    inValidEmail: "Неверный адрес электронной почты",
    invalidPassword: 'Неверный пароль',
    passwordNoMatch: "Пароли не совпадают",
    invalidEmailPwd: "Неправильный адрес электронной почты или пароль"
  },
};
