export const LABELS = {
  dashboard: "Panel",
  overview: "Descripción general",
  accounts: "Cuentas",
  support: "Apoyo",
  queue:"Cola",
  shipping: "Envío",
  todo: "Hacer",
  leads: "Dirige",
  fmcsa: "Fmcsa",
  audit: "Auditoría",
  users: "Usuarios",
  settings: "Ajustes",
  reseller: "Revendedores",
  dailyLogs: "Registros diarios",
  dvir: "Dvir",
  dutyStatus: "Estado de servicio",
  tracking: "Seguimiento",
  unidentified: "No identificado",
  malfunctions: "Mal funcionamiento",
  violations: "Violaciones",
  notifications: "Notificaciones",
  document: "Documento",
  logEdits: "Ediciones de registro",
  liveShare: "Compartir en vivo",
  stateMileage: "Millaje estatal",
  reports: "Informes",
  fleetmanager: "Gerente de la flota",

  carrier: "Transportador",
  terminal: "Terminal",
  equipment: "Equipo",
  editReason: "Editar motivo",
  profile: "Perfil",
  api: "API",
  alerts: "Alertas",
};
