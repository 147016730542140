export const LABELS={
    addsupportcase:"AÑADIR CASO DE SOPORTE",
    addShipping:"AÑADIR ENVÍO",
    addtodo:"AÑADIR TODO",
    
    account: "Cuenta",
    driver: "Conductor",
    category:"Categoría",
    type:"Tipo",
    product:"Producto",

    priority:"Prioridad",
    status:"Estado",
    time:"Tiempo",
    assignedto:"Asignado a",
    assignee_email:"Correo electrónico del cesionario",
    description:"Descripción",

    issue:"Asunto",
    detail:"Detalle",
    trackingUrl:"URL de seguimiento",

    Solution:"Solución",

    caller_name:"Nombre de la persona que llama",
    caller_email:"correo electrónico de la persona que llama",
    caller_phone:"Teléfono que llama",


    callback:"Llamar de vuelta",
    callback_time:"Tiempo de devolución de llamada",
    name:"Nombre",
    email:"Correo electrónico",
    phone:"Teléfono",
    saving:"Ahorro...",
    save:"AHORRAR",
    closed:"CERRADO",
    search:"Buscar",
    clear:"Claro",
    new:"NUEVO",
    close:"CERCA",


    Info:"Información",
    Caller:"Llamador",
    supportPlan:"PLANES DE APOYO",
    supportticket:"ticket de soporte",
    reason:"Razón",
    driverPhone:"Teléfono del conductor",
    driveremail:"Correo electrónico del conductor",
    addSupportTicket:"Agregar ticket de soporte",


    PleaseWaitLoading:"Espere, cargando....",
    successMessage: "El caso de soporte se guardó exitosamente",
    response :"Algo salió mal, inténtalo después de un tiempo.",






    refresh: "Actualizar",
    recap: "Resumen",
    date: "Fecha",
    newTab: "Nueva pestaña",
    download: "Descargar",
    Audit: "Auditoría",
    ReassignMultipleStatus: "Reasignar estado múltiple",
    AddMultipleStatus: "Agregar varios estados",
    EditMultipleStatus: "Editar estados múltiples",
    EditMultipleStatusAdmin: "Editar estados múltiples (Administrador)",
    AddStatus: "Agregar estado",
    SendMessage: "Enviar mensaje",
    SendNotification: "Enviar notificación",
    AddShippingOrTrailer: "Agregar envío o remolque",
    AddStatusAdmi: "Agregar estado (administrador)",
    ReassignMultipleStatusAdmin: "Reasignar varios estados (Administrador)",


   

    rowsperpage: "Filas por página",


}