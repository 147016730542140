export const LABELS={
    addsupportcase:"ДОБАВИТЬ СЛУЧАЙ ПОДДЕРЖКИ",
    addShipping:"ДОБАВИТЬ ДОСТАВКУ",
    addtodo:"ДОБАВИТЬ ВСЁ",
    
    account: "Счет",
    driver: "Водитель",
    category:"Категория",
    type:"Тип",
    product:"Продукт",

    priority:"Приоритет",
    status:"Положение дел",
    time:"Время",
    assignedto:"Назначено",
    assignee_email:"Адрес электронной почты правопреемника",
    description:"Описание",


    issue:"Проблема",
    detail:"Деталь",
    trackingUrl:"URL-адрес отслеживания",

    Solution:"Решение",

    caller_name:"Имя звонящего",
    caller_email:"Адрес электронной почты вызывающего абонента",
    caller_phone:"Телефон вызывающего абонента",


    callback:"Перезвонить",
    callback_time:"Время обратного звонка",
    name:"Имя",
    email:"Электронная почта",
    phone:"Телефон",
    saving:"Сохранение...",
    save:"СОХРАНЯТЬ",
    closed:"ЗАКРЫТО",
    search:"Поиск",
    clear:"Прозрачный",
    new:"НОВЫЙ",
    close:"ЗАКРЫВАТЬ",



    Info:"Информация",
    Caller:"Звонящий",
    supportPlan:"ПЛАНЫ ПОДДЕРЖКИ",
    supportticket:"Билет в службу поддержки",
    reason:"Причина",
    driverPhone:"Телефон водителя",
    driveremail:"Электронная почта водителя",
    addSupportTicket:"Добавить заявку в службу поддержки",
    

    

    PleaseWaitLoading:"Пожалуйста, подождите, идет загрузка....",
    successMessage: "Запрос в службу поддержки успешно сохранен.",
    response :"Что-то пошло не так, попробуйте через некоторое время.",

    rowsperpage: "Строков на странице",
    

    

    
    

   

}