export const LABELS={
    addsupportcase:"ਸਹਾਇਤਾ ਕੇਸ ਸ਼ਾਮਲ ਕਰੋ",
    addShipping:"ਸ਼ਿਪਿੰਗ ਸ਼ਾਮਲ ਕਰੋ",
    addtodo:"ਟੂਡੋ ਸ਼ਾਮਲ ਕਰੋ",
    
    account: "ਖਾਤਾ",
    driver: "ਡਰਾਈਵਰ",
    category:"ਸ਼੍ਰੇਣੀ",
    type:"ਕਿਸਮ",
    product:"ਉਤਪਾਦ",

    priority:"ਤਰਜੀਹ",
    status:"ਸਥਿਤੀ",
    time:"ਸਮਾਂ",
    assignedto:"ਨੂੰ ਦਿੱਤਾ",
    assignee_email:"ਜ਼ਿੰਮੇਦਾਰ ਈਮੇਲ",
    description:"ਵਰਣਨ",


    issue:"ਮੁੱਦੇ",
    detail:"ਵੇਰਵੇ",
    trackingUrl:"ਟਰੈਕਿੰਗ url",

    Solution:"ਦਾ ਹੱਲ",

    caller_name:"ਕਾਲਰ ਦਾ ਨਾਮ",
    caller_email:"ਕਾਲਰ ਈਮੇਲ",
    caller_phone:"ਕਾਲਰ ਫ਼ੋਨ",


    callback:"ਵਾਪਸ ਫੋਨ ਮਲਾਓ",
    callback_time:"ਕਾਲਬੈਕ ਸਮਾਂ",
    name:"ਨਾਮ",
    email:"ਈ - ਮੇਲ",
    phone:"ਫ਼ੋਨ",
    saving:"ਰੱਖਿਅਤ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
    save:"ਬਚਾਓ",
    closed:"ਬੰਦ",
    search:"ਖੋਜ",
    clear:"ਸਾਫ਼",
    new:"ਨਵਾਂ",
    close:"ਬੰਦ ਕਰੋ",



    Info:"ਜਾਣਕਾਰੀ",
    Caller:"ਕਾਲਰ",
    supportPlan:"ਸਹਾਇਤਾ ਯੋਜਨਾਵਾਂ",
    supportticket:"ਸਹਾਇਤਾ ਟਿਕਟ",
    reason:"ਕਾਰਨ",
    driverPhone:"ਡਰਾਈਵਰ ਫ਼ੋਨ",
    driveremail:"ਡਰਾਈਵਰ ਈਮੇਲ",
    addSupportTicket:"ਸਹਾਇਤਾ ਟਿਕਟ ਸ਼ਾਮਲ ਕਰੋ",
    

    

    PleaseWaitLoading:"ਕਿਰਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ, ਲੋਡ ਹੋ ਰਿਹਾ ਹੈ...",
    successMessage: "ਸਹਾਇਤਾ ਕੇਸ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ ਹੈ",
    response :"ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ, ਕਿਰਪਾ ਕਰਕੇ ਕੁਝ ਸਮੇਂ ਬਾਅਦ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",

    rowsperpage: "ਪ੍ਰਤੀ ਪੰਨਾ ਕਤਾਰਾਂ",
    

    

    
    

   

}