export const LABELS = {
  DASHCAMERA: {
    header: "ਏਕੀਕ੍ਰਿਤ ਵੀਡੀਓ ਇੱਥੇ ਹੈ।",
    header2:
      "Osrit Dashcam ਕਠੋਰ ਡ੍ਰਾਈਵਿੰਗ ਇਵੈਂਟਸ ਦੇ ਵਿਜ਼ੂਅਲ ਸੰਦਰਭ ਪ੍ਰਦਾਨ ਕਰਕੇ ਡਰਾਈਵਰ ਦੇ ਵਿਵਹਾਰ ਨੂੰ ਬਿਹਤਰ ਬਣਾਉਣ ਅਤੇ ਤੁਹਾਡੇ ਕਾਰੋਬਾਰ ਲਈ ਜੋਖਮਾਂ ਨੂੰ ਘਟਾਉਣ ਵਿੱਚ ਤੁਹਾਡੀ ਮਦਦ ਕਰਦਾ ਹੈ।",
    header3: "ਹੋਰ ਜਾਣਨ ਲਈ, ਕਿਰਪਾ ਕਰਕੇ 'ਤੇ ਜਾਓ",
    header3Link: "www.truckdashcamera.com",
  },
  OSRIT: {
    header: "ਕਲਾਉਡ ਟਰੱਕਿੰਗ ਸੌਫਟਵੇਅਰ | ਟੀ.ਐੱਮ.ਐੱਸ",
    header2:
      "Osrit Cloud Software ਤੁਹਾਨੂੰ FTL ਜਾਂ LTL ਲੋਡ, ਗਾਹਕਾਂ, ਦਲਾਲਾਂ, ਡਰਾਈਵਰਾਂ, ਪੇਰੋਲ, ਇਨਵੌਇਸਿੰਗ ਆਦਿ ਨੂੰ ਇੱਕ ਥਾਂ ਤੋਂ ਆਸਾਨੀ ਨਾਲ ਭੇਜਣ ਵਿੱਚ ਮਦਦ ਕਰਦਾ ਹੈ!",
    header3: "ਹੋਰ ਜਾਣਨ ਲਈ, ਕਿਰਪਾ ਕਰਕੇ 'ਤੇ ਜਾਓ",
    header3Link: "www.osrit.com",
  },
  SIGN_IN_FORM: {
    email: "ਈ - ਮੇਲ",
    password: "ਪਾਸਵਰਡ",
    logInBtn: "ਲਾਗਿਨ",
    logInLoading: "ਲੋਡ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
    forgetPwd: "ਪਾਸਵਰਡ ਭੁੱਲ ਜਾਓ",
    required: "ਲੋੜੀਂਦਾ ਹੈ",
    inValidEmail: "ਗਲਤ ਈਮੇਲ ਪਤਾ",
    invalidPassword: 'ਗਲਤ ਪਾਸਵਰਡ',
    passwordNoMatch: "ਪਾਸਵਰਡ ਮੇਲ ਨਹੀਂ ਖਾਂਦੇ",
    invalidEmailPwd: "ਅਵੈਧ ਈਮੇਲ ਜਾਂ ਪਾਸਵਰਡ"
  },
};
