// https://www.kvaser.com/about-can/can-standards/j1587/
// https://embeddedflakes.com/j1939-diagnostics-part-1/
// https://embeddedflakes.com/wp-content/uploads/2018/10/J1939-DTC-Conversion-Methods.jpg
// CM0/CM1:  file:///Users/mikhailmasliukov/Downloads/J1939_73_201307_Application%20layer%20diagnostics%20DM1%20Questions.pdf

function J1939_parse_number(dtc, version) {
  // var origin = dtc;
  var OC = 0;
  var CM = 0;
  var FMI = 0;
  var SPN = 0;
  OC = dtc & 0x7f;
  dtc >>= 7;
  CM = dtc & 0x1;
  dtc >>= 1;
  FMI = dtc & 0xf;
  dtc >>= 5;
  // eslint-disable-next-line
  if (CM == 0 || version == undefined) {
    version = 4;
  }
  // eslint-disable-next-line
  switch (version) {
    case 1:
      SPN = dtc;
      break;
    case 2:
      var temp = dtc & 0x7;
      dtc >>= 3;
      SPN = dtc & 0xff;
      dtc >>= 8;
      SPN <<= 8;
      SPN |= dtc & 0xff;
      SPN <<= 3;
      SPN |= temp;
      break;
    case 3:
    case 4:
      SPN = dtc & 0x7;
      dtc >>= 3;
      SPN <<= 3;
      SPN |= dtc & 0xff;
      dtc >>= 8;
      SPN <<= 8;
      SPN |= dtc & 0xff;
      break;
  }
  // console.log("Version: "+ version+": DTC: 0x" + origin.toString(16).padStart(8, '0') + " -> SPN:" + SPN, "FMI:" + FMI, "CM:" + CM, "OC:" + OC);
  return { spn: SPN, fmi: FMI, cm: CM, oc: OC };
}

// eslint-disable-next-line
function J1939_parse_list(list, version) {
  var dtcs = [];
  list.forEach(function (s) {
    dtcs.push(J1939_parse_number(s, version));
  });
  return dtcs;
}

// eslint-disable-next-line
function J1587_parse_number(data) {
  var b3 = data & 0xff;
  var b2 = (data >> 8) & 0xff;
  var b1 = data >> 16;
  var response = [];
  response.push(b1);
  response.push(b2);
  // eslint-disable-next-line
  if ((b2 & 0x80) != 0) {
    response.push(b3);
  }
  return response;
}

// console.log(J1939_parse_number(0x0097038A, 1));
// console.log(J1939_parse_number(0x9700038A, 2));
// console.log(J1939_parse_number(0xB804038A, 3));
// console.log(J1939_parse_number(0xB804030A));
//B804030A
// console.log(J1587_parse_number(0x010203));
// console.log(J1587_parse_number(0x018203));
// console.log(J1939_parse_string("B804030A"));
// console.log(J1939_parse_string("90020302"));
// console.log(J1939_parse_list([0x18F0FF7E,0x0000007F,0x0000007F,0x39F2E57E,0xB804030A]));
// console.log(J1939_parse_list_strings(["18F0FF7E","0000007F","0000007F","39F2E57E","B804030A"]));

export function J1939_parse_list_strings(list, version) {
  var dtcs = [];
  list.forEach(function (s) {
    s = s.trim();
    dtcs.push(J1939_parse_number(parseInt(s, 16), version));
  });
  return dtcs;
}

export function J1939_parse_string(str, version) {
  var dtcs = [];
  str.split(",").forEach(function (s) {
    s = s.trim();
    const result = J1939_parse_number(parseInt(s, 16), version);
    if (result.spn !== 0) {
      dtcs.push(result);
    }
  });
  return dtcs;
}
