export const LABELS={
  addCarrier :"ДОБАВИТЬ ПЕРЕВОЗЧИКА",
  editCarrier:"ИЗМЕНИТЬ ПЕРЕВОЗЧИКА",
  edit_Terminal:"РЕДАКТИРОВАНИЕ ТЕРМИНАЛА",
  add_Terminal:"ДОБАВИТЬ ТЕРМИНАЛ",
  addAccount:"ДОБАВИТЬ АККАУНТ",

  editEquipment :"РЕДАКТИРОВАНИЕ ОБОРУДОВАНИЯ",
  addEquipment:"ДОБАВИТЬ ОБОРУДОВАНИЕ",

  editTrailer:"РЕДАКТИРОВАТЬ ТРЕЙЛЕР",
  addTrailer:"ДОБАВИТЬ ТРЕЙЛЕР",

  editReasons:"ИЗМЕНИТЬ ПРИЧИНЫ",

  addUser:"ДОБАВИТЬ ПОЛЬЗОВАТЕЛЯ",
  editUser:"ИЗМЕНИТЬ ПОЛЬЗОВАТЕЛЯ",
  driver_details:"ИНФОРМАЦИЯ О ВОДИТЕЛЕ",
  update:"ОБНОВЛЯТЬ",
  addApi_key:"ДОБАВИТЬ API-КЛЮЧ",

  logingUsers:"Вход пользователей",
  supportCentre:"Центр поддержки",


  name:"Имя",
  dot:"DOT",
  timezone:"Часовой пояс",
  country:"Страна",
  address_line1:"Адресная строка 1",
  suit_bldg:"Suit.,bldg",
  city:"Город",
  state:"Состояние",
  zip:"Zip",
  contactName:"Контактное лицо",
  email:"Электронная почта",
  phone:"Телефон",
  role:"Роль",
  dispatchName:"Имя отправки",
  note:"NOTE :",
  info:"Информация об отправке требуется только в том случае, если вы хотите отправить отправку на устройство ELD в виде push-уведомлений.",
  save:"СОХРАНЯТЬ",
  saving:"Сохранение...",
  cancel:"ОТМЕНА",
  close:"ЗАКРЫВАТЬ",
  roles:"Роли",
  access:"Доступ",
  cargo:"Груз",
  hos:"HOS",
  dutyLogs:"ДЕЖУРНЫЕ ЖУРНАЛИ",
  contact:"Контакт",
  required:"Необходимый",
  billing: "Биллинг",


  accountName:"Имя учетной записи",
  contactEmail:"Почта для связи",
  contactPhone:"Контактный телефон",
  contactRole:"Контактная роль",
  stripe:"Полоса",
  lastActive:"Последнее посещение",


 driverId:"Идентификатор водителя",
 firstName:"Имя",
 lastName:"Фамилия",
 mobile:"мобильный",
 password:"Пароль",
 licCountry:"Лик Кантри",
 licState:"Лицензионный штат",
 licNumber:"Номер лицензии",
 cycleUsa:"Велоспорт США",
 cycleCA:"Цикл СА",
 startTime:"Время начала",
 receiverMail:"Электронная почта получателя",
 personal:"Персональный",
 yardMoves:"Дворовые переезды",
 allowEdit:"Разрешить редактирование",
 tracking:"Отслеживание",
 exempt:"Освобождать",
 exemptDriver:"Освобожденный водитель",
 exemptAnnotation:"Освобожденная аннотация",
 assignedVehicle:"Назначенный автомобиль",
 currentVehicle:"Текущий автомобиль",
 currentTrailer:"Текущий трейлер",
 enterMobileNumber:"Введите номер мобильного телефона",
 appHome_View:"Главная страница приложения",
 firebase:"Огневая база",
 apiKey:"API-ключ",
 scopes:"Области применения",
 message:"Сообщение",
 sendNow:"Отправить сейчас",
 loading:"Загрузка...",
 sendVia_whatsapp:"Отправить через WhatsApp",
 send_sms:"ОТПРАВИТЬ СМС",
 speed:"Скорость",



 prefix:"Префикс",
 state_mileage_report:"Государственный отчет о пробеге",
 pdf:"PDF",
 details:"ПОДРОБНОСТИ",
 carrierName:"Имя оператора связи",
 vehicleName:"Название автомобиля",
 miles:"Майлз",
 mileage_miles:"Mileage(Майлз)",
 quarter:"Четверть",
 report:"Отчет",
 select_vehicle:"Выберите автомобиль",
 odometer:"Одометр",
 superAdmin:"Суперадминистратор",
 supportAdmin:"Администратор поддержки",


  No_vehicle_is_assignedTO_deiver:" Ни одно транспортное средство не закреплено за водителем",
  please_set_current_vehicle:"Пожалуйста, укажите текущий автомобиль",



 
  endTime:"Время окончания",
  duration:"Продолжительность",
  recordStatus:"Статус записи",
  recordOrigin:"Запись происхождения",
  location:"Расположение",
  assign:"Назначать",
  driver:"Водитель",


  diagostic$Malfunction:"Диагностика/Неисправность",
  eventCode:"Код события",
  eventTime:"Время события",





  status:"Положение дел",
  terminal:"Терминал",
  address:"Адрес",
  title:"Заголовок",
  body:"Тело",
  time:"Время",
  user:"Пользователь",
  allUser:"Все пользователи",
  sendNotification:"Отправить уведомление",
  send:"Отправлять",
  syncAll:"СИНХРОНИЗИРОВАТЬ ВСЕ",
  inspector:"Инспектор",
  condition:"Состояние",
  start_end:"Начать/конец",
  view:"ВИД",
  date:"Дата",
  _note:"Примечание",
  _details:"Подробности",
  _view:"Вид",
  settings:"Настройки",
  select:"Выбирать",
  coordinates:"Координаты",
  event:"Событие",
  table:"СТОЛ",
  map:"КАРТА",
  trips:"TПутешествия",
  delete:"УДАЛИТЬ",
  edit:"Редактировать",
  



  driver_vehicle_inspection_report:" ОТЧЕТ ВОДИТЕЛЬСКОГО ПРОВЕРКИ АВТОМОБИЛЯ (DVIR)",
  AS_REQUIREDBY_THE_Dot_FEDERAL_MOTOR_CARRIER_SAFETY_REGULATIONS:"КАК ТРЕБУЕТСЯ D.O.T. ФЕДЕРАЛЬНЫЕ ПРАВИЛА БЕЗОПАСНОСТИ ДЛЯ АВТОПЕРЕВОЗЧИКОВ",
  dvirNumber:"Номер ДВИР:-",
 start_inspection:"Начать проверку",
 endInspection:"Завершение проверки",
 inspection_type:"Тип проверки",
 inspection_Area:"Области проверки",
 I_declare_that_this_asset_has_been_inspected_in_accordance_with_the_applicable_requirements:"Я заявляю, что этот актив был проверен в соответствии с применимыми требованиями.",
 driver_signature:"Подпись водителя",



  download:"Скачать",
  downloading:"Downloading...",
  not_able_to_download:"Не могу скачать",
  missingFile:"Отсутствует файл",



  history:"ИСТОРИЯ",
  send_to_fmcsa:"ОТПРАВИТЬ В FMCSA",
  email_to_fmcsa:"ЭЛЕКТРОННАЯ ПОЧТА В FMCSA",
  SelectDriver:"Выберите драйвер",
  fromDate:"С даты",
  toDate:"На сегодняшний день",
  comments:"Комментарии",
  fmcsa_data_transfer:"Передача данных FMCSA (шаги по передаче данных)",
  selectDateRange:"Выберите диапазон дат",
  enterComment:"Введите комментарий",
  sendDataTo_fmcsa:"Нажмите «Отправить в FMCSA», чтобы передать данные в FMCSA.",




   showall:"Показать все",
   vehicle:"ТРАНСПОРТНОЕ СРЕДСТВО",
   trailer:"ТРЕЙЛЕР",
   search:"Поиск...",
   add:"ДОБАВЛЯТЬ",
   liveshare:"Прямая трансляция",
   create_ifta:"IFTA СОЗДАВАТЬ",
   no_data_found:" Данные не найдены!",
   no_recordFound:"Записи не найдены",


   regulationMode:"Режим регулирования",
   plate:"Тарелка",
   eld:"ELD",
   created_edited:"Создано/Отредактировано",
   created:"Созданный",
   edited:"Отредактировано",


   rowsperpage: "Строков на странице",


//   <------ADD equipment Form------->

   vin:"Vin",
   make:"Делать",
   model:"Модель",
   year:"Год",
   fuel:"Топливо",
   type:"Тип",
   licPlate:"Lic Plate",
   dvirForm:"Форма Dvir",
   gps_source:"GPS-источник",
   devicetype:"Тип устройства",
   devicename:"Имя устройства",
   macAddrerss:"MAC-адрес",
   eld_serial:"ELD Серийный",
   eld_firmware:"ELD Прошивка",
   eld_BleVersion:"ELD БлеВерсия",
   connectionType:"Тип соединения",
   dashcam:"Видеорегистратор",
   odometer_offset:"Смещение одометра",
   active:"Активный",
   codes:"Коды",
   ifta:"Ifta",
   vbusConnection:"Для вождения требуется подключение VBUS",
   carrier:"Перевозчик",
   dvir :"Dvir",
   licExpiry:"Срок действия лицензии истек",
   adminScope:"ОБЛАСТИ АДМИНИРОВАНИЯ",


   dashcameraId:"Идентификатор видеорегистратора",
   reseller:"Реселлер",
   updateFrequency:"Частота обновления",
   bucket:"Ведро",
   theme:"Тема",
   kioskpin:"Kioskpin",
   hereAppId:"Здесь идентификатор приложения",
   hereApiKey:"Вот ключ API",
   updateLocation:"Обновить местоположение",
   debug:"Отлаживать",
   allLogs:"Все журналы",
   editLogs:"Редактировать журналы",
   documents:"Документы",
   notification:"Уведомления",
   addDvir:"Добавить Двир",
   appSettings:"Настройки приложения",
   appAll_Log:"Приложение «Все журналы»",
   appAllLogsFmcsa:"Приложение Все журналы Fmcsa",
   estimatedWaitTime:"Примерное время ожидания",
   minutes:"минута(ы)",






  noteinfo:"ПРИМЕЧАНИЕ. Как только вы добавите новый автомобиль, средства с вашей карты будут автоматически списаны.",
 ifta_info_note: "IFTA доступна только для активных грузовиков.",
 ifta_info:"Если вы хотите отключить или удалить грузовик, перед отключением загрузите состояние пробега.",
 saved_Successfully:"Saved successfully",
 updateSuccessfully:"Успешно Обновлено",
 sendSuccessfully:"Смс отправлено успешно",
 successMessage_carrier:"Оператор успешно сохранен.",
 successMessage: "Трейлер успешно сохранен.",
 successMessage_Equipment: "Оборудование успешно сохранено",
 please_try_aftersometime:"Пожалуйста, попробуйте через некоторое время.",
 successMessage_user:"Пользователь успешно добавлен",
 successMessage_Api_Key:"Ключ API успешно добавлен.",
 response:"Что-то пошло не так, попробуйте позже.",
 errorMessage:"Что-то пошло не так, попробуйте позже.",
 editReasonUpdated:"Причины редактирования успешно обновлены.",
 logs_are_successfully_submitted:"Журналы успешно отправлены.",
 user_is_updated_successfully:"Пользователь успешно обновлен.",
 driver_is_cleaned_successfully:"Драйвер успешно очищен.",
 account_is_updated_successfully:"Аккаунт успешно обновлен",
 Note_only_requiredforSetNewPassword:"Примечание. Требуется только в том случае, если вы хотите установить новый пароль",
 driver_is_required:"Требуется водитель.",
 from_date_is_required:"Требуется дата с даты.",
 to_date_is_required:"На сегодняшний день требуется.",
 comment_is_required:"Комментарий обязателен.",
 comment_is_invalid:"Комментарий недействителен.",

 are_you_sure_WantTo_delete:"Вы уверены, что хотите удалить dvir?",
 errorMassage:"Что-то пошло не так, попробуйте позже.",
 deletedsucessfully:"Удален успешно.",
 deleting:"Удаление..."






}
