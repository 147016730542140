import _get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import { withStyles } from "@mui/styles";
import PeopleIcon from "@mui/icons-material/People";
import Button from "@mui/material/Button";
import { LABELS } from "../language";
import BuildIcon from '@mui/icons-material/Build';

const ALL_LABELS = LABELS.navigation;

const driverTabs = {
  baseUrl: "/dashboard",
  tabs: [
    {
      id: "Carrier",
      label: ALL_LABELS.carrier,
      icon: <PeopleIcon />,
      active: true,
      url: "/settings/carrier",
    },
    {
      id: "Terminal",
      label: ALL_LABELS.terminal,
      icon: <PeopleIcon />,
      active: true,
      url: "/settings/terminal",
    },
    {
      id: "Equipment",
      label: ALL_LABELS.equipment,
      icon: <PeopleIcon />,
      active: true,
      url: "/settings/equipment",
    },
    {
      id: "maintenance",
      label: 'Maintenance',
      icon: <BuildIcon />,
      active: true,
      url: "/settings/maintenance",
    },
    // {
    //     id: "NEW TRAILER",
    //     icon: <PeopleIcon />,
    //     active: true,
    //     url: "/settings/trailer"
    // },
    {
      id: "Users",
      label: ALL_LABELS.users,
      icon: <PeopleIcon />,
      active: true,
      url: "/settings/user",
    },
    // {
    //     id: "ACCOUNT",
    //     icon: <PeopleIcon />,
    //     active: true,
    //     url: "/settings/account"
    // },

    // { id: 'MOBILE APP', icon: <PeopleIcon />, active: true, url: '/settings/app' },
    {
      id: "Edit Reason",
      label: ALL_LABELS.editReason,
      icon: <PeopleIcon />,
      active: true,
      url: "/settings/editreason",
    },
    // {
    //     id: "EXCEPTIONS",
    //     icon: <PeopleIcon />,
    //     active: true,
    //     url: "/settings/exceptions"
    // },
    {
      id: "Profile",
      label: ALL_LABELS.profile,
      icon: <PeopleIcon />,
      active: true,
      url: "/settings/profile",
    },
    {
      id: "Api",
      label: ALL_LABELS.api,
      icon: <PeopleIcon />,
      active: true,
      url: "/settings/api",
    },
    {
      id: "Alerts",
      label: ALL_LABELS.alerts,
      icon: <PeopleIcon />,
      active: true,
      url: "/settings/notification",
    },
  ],
};

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  button: {
    margin: theme.spacing(1),
    // color: "white",
    // paddingBottom: 2,
    // fontSize: "0.875rem"
    // borderRadius: 0,
    // borderBottom: "3px solid trabsparent",
  },
  active: {
    // borderBottom: "3px solid white",
    // fontSize: "1rem",
    // paddingBottom: 0
  },
});

class UserNavigator extends React.Component {
  handleClick = (url) => {
    this.props.history.push(url);
  };

  render() {
    const { classes, accountId } = this.props;
    const baseUrl = driverTabs.baseUrl;

    return (
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="transparent"
        position="static"
        elevation={0}
      >
        <div>
          {driverTabs.tabs.map(({ id, label, url }) => {
            if (accountId) {
              url = `${baseUrl}/${accountId}${url}`;
            }

            let buttonWeight = 400;

            if (url === _get(this, "props.location.pathname")) {
              buttonWeight = 800;
            }

            return (
              <Button
                key={id}
                className={classes.button}
                onClick={() => this.handleClick(url)}
              >
                <span
                  style={{
                    fontWeight: buttonWeight,
                  }}
                >
                  {label}
                </span>
              </Button>
            );
          })}
        </div>
      </AppBar>
    );
  }
}

UserNavigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(UserNavigator));
