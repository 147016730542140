import React from "react";
import _get from "lodash/get";
// import html2pdf from "html2pdf.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Formik, Form, Field } from "formik";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
// import CheckBox from "../../components/CheckBox";
import InputDateBox from "../../components/InputDateBox";
import {
  getDriverDvirTemplates,
  getDriverSignById,
  updateDriverDvir,
} from "../../actions/dvirActions";
import {
  formatDateTime,
  formatDate,
  formatTime,
  formatDateTimeUtc,
  formatDateTimeToUtc,
} from "../../actions/momentActions";
import { getEquipments } from "../../actions/equipmentActions";
import { getTrailers } from "../../actions/trailerActions";
import { getDrivers } from "../../actions/driverActions";
import { pdfOptions } from "../../helpers/helpers";
import Error from "../Error";
import Success from "../Success";
import { defaultValues, validate } from "./validate";

const styles = (theme) => ({
  wrapper: {},
  table: { border: "1px solid #ccc", marginBottom: 25 },
  DialogActions: {
    marginTop: 40,
    justifyContent: "center",
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const inspectionTypes = [
  { name: "Posttrip", id: "postTrip" },
  { name: "Pretrip", id: "preTrip" },
  { name: "Ad hoc", id: "adHoc" },
];

const vehicleTypes = [
  { name: "Tractor", id: "tractor" },
  { name: "Trailer", id: "trailer" },
];

const inspectorTypes = [
  { name: "Driver", id: "driver" },
  { name: "Mechanic", id: "mechanic" },
  { name: "Other", id: "other" },
];

const equipmentConditions = [
  { name: "Defects corrected", id: "defectsCorrected" },
  { name: "Un satisfactory", id: "unSatisfactory" },
  { name: "Satisfactory", id: "satisfactory" },
  { name: "Nodefect", id: "noDefect" },
];

export class AddDvirDialog extends React.Component {
  state = {
    email: "",
    carrier: null,
    successMessage: "",
    errorMessage: "",
    loading: false,
    forms: [],
    templatesOrg: [],
    areas: [],
    signData: null,
    vehicles: [],
    trailers: [],
    drivers: [],
  };

  componentDidMount = async () => {
    await this.loadSign();
    await this.loadTemplates();
    await this.getEquipments();
    await this.getTrailers();
    await this.getDrivers();
  };

  getEquipments = async () => {
    const payload = {
      skip: 0,
      limit: 100,
      active: "true",
      assetType: "VEHICLE",
    };

    const vehicles = await this.props.getEquipments(payload);
    // console.log("vehicles ::: ", vehicles);
    this.setState({ vehicles: vehicles });
  };

  getTrailers = async () => {
    const payload = {
      skip: 0,
      limit: 100,
      active: "true",
      assetType: "VEHICLE",
    };

    const trailers = await this.props.getTrailers(payload);
    // console.log("trailers ::: ", trailers);
    this.setState({ trailers: trailers });
  };

  getDrivers = async () => {
    let payload = { skip: 0, limit: 250, status: "ACTIVE" };
    const drivers = await this.props.getDrivers(payload);
    this.setState({ drivers: drivers });
  };

  loadSign = async (e) => {
    const driverId = _get(this, "props.selected.driverId");
    try {
      const response = await this.props.getDriverSignById({ driverId });
      if (response.data) {
        this.setState({ signData: response.data });
      }
    } catch (e) {
      console.log("e :::: ", e);
    }
  };

  loadTemplates = async (e) => {
    try {
      const response = await this.props.getDriverDvirTemplates({});
      if (response && Array.isArray(response)) {
        const selected = this.props.selected || {};
        const vehicleType = selected.vehicleType || "";
        const templateId = selected.templateId || "";
        const _forms =
          response.filter((item) => {
            return item.assetType.includes(vehicleType.toUpperCase());
          }) || [];

        this.setState({
          templatesOrg: response,
          forms: _forms,
        });
      }
    } catch (e) {
      console.log("e :::: ", e);
    }
  };

  onSubmit = async (values) => {
    const timezone = this.props.timezone;
    const payload = {
      id: values._id,
      inspectionType: values.inspectionType,
      driverId: values.driverId,
      driverName: values.driverName,
      location: values.location,
      odometer: values.odometer,
      startTime: this.props.formatDateTimeToUtc(values.startTime, timezone),
      endTime: this.props.formatDateTimeToUtc(values.endTime, timezone),
      date: values.startTime,
      media: {
        folder: "dvir",
        name: "",
        bucket: "",
      },
    };

    try {
      this.setState({ successMessage: "", errorMessage: "", loading: true });
      const response = await this.props.updateDriverDvir(payload);
      if (response && response._id) {
        this.setState({
          successMessage: "Saved successfully.",
          loading: false,
        });
        setTimeout(() => this.props.afterSave(), 1000);
      } else {
        this.setState({ errorMessage: "Not able to saved.", loading: false });
      }
    } catch (e) {
      this.setState({ errorMessage: "Not able to saved.", loading: false });
    }
  };

  render() {
    const { classes, selVehicle, selTrailer } = this.props;
    const { errorMessage, successMessage, loading } = this.state;
    const { signData, showSign } = this.state;
    const areas = this.state.areas || [];
    const timezone = this.props.timezone;
    const forms = this.state.forms || [];
    const selected = this.props.selected || {};
    const drivers = this.state.drivers || [];
    const vehicles = this.state.vehicles || [];
    const trailers = this.state.trailers || [];

    // console.log("forms = ", forms);
    if (selected.startTime) {
      selected.startTime = this.props.formatDateTimeUtc(
        selected.startTime,
        timezone
      );
    }
    if (selected.endTime) {
      selected.endTime = this.props.formatDateTimeUtc(
        selected.endTime,
        timezone
      );
    }
    // const _sel = selected.areas || [];
    // selected.areas = _sel.map((item) => {
    //   // const res = _areasSel.map((_item) => _item.name === item.name);
    //   // console.log("item.value 1 ==== ", item);
    //   // console.log("item.value 2 ==== ",!!parseInt(item.value));
    //   item.value = !!parseInt(item.value);
    //   return item;
    // });

    // console.log("selected.form ==== ", selected);

    return (
      <Dialog
        fullScreen={false}
        className={classes.wrapper}
        open={this.props.open}
        maxWidth={"lg"}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.onClose}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
              {"Update Dvir"}
            </Grid>
            <Grid item xs={12} md={4}>
              <div style={{ textAlign: "right", padding: 0 }}>
                <IconButton aria-label="close" onClick={this.props.handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <div style={{ maxWidth: "100%", margin: "auto" }} id={"dvirPdf"}>
            <h3 style={{ textAlign: "center" }}>
              DRIVER'S VEHICLE INSPECTION REPORT (DVIR)
            </h3>
            <h5 style={{ textAlign: "center" }}>
              AS REQUIRED BY THE D.O.T. FEDERAL MOTOR CARRIER SAFETY REGULATIONS
            </h5>
            <div>
              <Formik
                initialValues={selected}
                validate={() => {}}
                onSubmit={this.onSubmit}
                enableReinitialize={true}
              >
                {({ values, handleSubmit, setFieldValue, errors, ...rest }) => {
                  return (
                    <Form style={{ padding: "20px 10px" }}>
                      <Grid container spacing={3}>
                        <SelectBox
                          md={3}
                          name="inspectorType"
                          label="Inspector"
                          items={inspectorTypes}
                          disabled={true}
                        />
                        <SelectBox
                          md={3}
                          name="inspectionType"
                          label="Inspection type"
                          items={inspectionTypes}
                        />
                        <SelectBox
                          md={3}
                          name="vehicleType"
                          label="Vehicle type"
                          items={vehicleTypes}
                          disabled={true}
                          onChange={(e) => {
                            const _id = e.target.value;
                            const _org = this.state.templatesOrg || [];
                            const _forms = _org.filter((item) =>
                              item.assetType.includes(_id.toUpperCase())
                            );
                            this.setState({ forms: _forms });
                          }}
                        />

                        <SelectBox
                          md={3}
                          name="templateId"
                          label="Form"
                          selValue={"name"}
                          selLabel={"name"}
                          items={forms}
                          disabled={true}
                          onChange={(e) => {
                            const _id = e.target.value;
                            const _forms = this.state.forms || [];
                            const _obj = _forms.find((_i) => _i.name === _id);
                            this.setState({ areas: _obj.areas });
                          }}
                        />

                        <SelectBox
                          md={3}
                          name="driverId"
                          label="Driver"
                          selValue={"id"}
                          selLabel={"firstName"}
                          selLabelTwo={"lastName"}
                          items={drivers}
                          onChange={(e) => {
                            const _id = e.target.value;
                            const _dr =
                              drivers.find((item) => item.id === _id) || [];
                            setFieldValue(
                              "driverName",
                              `${_dr.firstName} ${_dr.lastName}`
                            );
                          }}
                        />
                        {values.vehicleType === "tractor" && (
                          <SelectBox
                            md={3}
                            name="vehicleId"
                            label="Vehicle"
                            selValue={"id"}
                            selLabel={"name"}
                            items={vehicles}
                            disabled={true}
                          />
                        )}
                        {values.vehicleType === "trailer" && (
                          <SelectBox
                            md={3}
                            name="vehicleId"
                            label="Trailer"
                            selValue={"id"}
                            selLabel={"name"}
                            items={trailers}
                            disabled={true}
                          />
                        )}

                        <InputDateBox
                          md={3}
                          type={"datetime-local"}
                          name="startTime"
                          label="Start Date"
                          format={"MM/DD/yyyy hh:mm:ss A"}
                          maxDate={new Date()}
                        />
                        <InputDateBox
                          md={3}
                          type={"datetime-local"}
                          name="endTime"
                          label="End Date"
                          format={"MM/DD/yyyy hh:mm:ss A"}
                          maxDate={new Date()}
                        />

                        <Grid
                          sm={12}
                          md={12}
                          item
                          style={{
                            padding: 14,
                            marginTop: 20,
                          }}
                        >
                          <Grid container>
                            {values.areas &&
                              values.areas.map((item, idx) => {
                                return (
                                  <Grid item sm={12} md={3} key={`key-${idx}`}>
                                    {parseInt(values.areas[idx].value) ? (
                                      <CheckIcon />
                                    ) : (
                                      <CloseIcon />
                                    )}
                                    <span>{` ${values.areas[idx].name}`} </span>
                                  </Grid>
                                );
                              })}
                          </Grid>
                        </Grid>

                        <SelectBox
                          md={6}
                          name="equipmentCondition"
                          label="Equipment conditions"
                          items={equipmentConditions}
                          disabled={true}
                        />
                        <InputBox md={3} name="location" label="Location" />
                        <InputBox md={3} name="odometer" label="Odometer" />
                      </Grid>

                      {successMessage && <Success message={successMessage} />}
                      {errorMessage && <Error message={errorMessage} />}

                      {errors.startTime && (
                        <Error message={"Date is required"} />
                      )}
                      <div className={classes.DialogActions}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={loading}
                        >
                          {loading ? "Saving..." : "SAVE"}
                        </Button>{" "}
                        <Button
                          variant="contained"
                          onClick={this.props.handleClose}
                        >
                          {"CANCEL"}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timezone: _get(state, "appData.timezone", ""),
    loading: _get(state, "authData.termsBusy", false),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "authData.errorMessage", ""),
    profile: _get(state, "authData.profile", {}),
    selVehicle: _get(state, "authData.profile.vehicle", {}),
    selTrailer: _get(state, "authData.profile.trailer", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDriverSignById,
      getDriverDvirTemplates,
      updateDriverDvir,
      formatDateTime,
      formatTime,
      formatDate,
      formatDateTimeToUtc,
      formatDateTimeUtc,
      getEquipments,
      getTrailers,
      getDrivers,
      
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddDvirDialog))
);
