export const LABELS = {
  dashboard: "ਡੈਸ਼ਬੋਰਡ",
  overview: "ਸੰਖੇਪ ਜਾਣਕਾਰੀ",
  accounts: "ਖਾਤੇ",
  support: "ਸਪੋਰਟ",
  queue:"ਕਤਾਰ",
  shipping: "ਸ਼ਿਪਿੰਗ",
  todo: "ਕਰਨਾ",
  leads: "ਅਗਵਾਈ ਕਰਦਾ ਹੈ",
  fmcsa: "Fmcsa",
  audit: "ਆਡਿਟ",
  users: "ਉਪਭੋਗਤਾ",
  settings: "ਸੈਟਿੰਗਾਂ",
  reseller: "ਵਿਕਰੇਤਾ",
  dailyLogs: "ਰੋਜ਼ਾਨਾ ਲੌਗਸ",
  dvir: "Dvir",
  dutyStatus: "ਡਿਊਟੀ ਸਥਿਤੀ",
  tracking: "ਟਰੈਕਿੰਗ",
  unidentified: "ਅਣਜਾਣ",
  malfunctions: "ਖਰਾਬੀ",
  violations: "ਉਲੰਘਣਾਵਾਂ",
  notifications: "ਸੂਚਨਾਵਾਂ",
  document: "ਦਸਤਾਵੇਜ਼",
  logEdits: "ਲੌਗ ਸੰਪਾਦਨ",
  liveShare: "ਲਾਈਵ ਸ਼ੇਅਰ",
  stateMileage: "ਸਟੇਟ ਮਾਈਲੇਜ",
  reports: "ਰਿਪੋਰਟ",
  fleetmanager: "ਫਲੀਟ ਮੈਨੇਜਰ",

  carrier: "ਕੈਰੀਅਰ",
  terminal: "ਅਖੀਰੀ ਸਟੇਸ਼ਨ",
  equipment: "ਉਪਕਰਨ",
  editReason: "ਕਾਰਨ ਦਾ ਸੰਪਾਦਨ ਕਰੋ",
  profile: "ਪ੍ਰੋਫਾਈਲ",
  api: "Api",
  alerts: "ਚੇਤਾਵਨੀਆਂ",
};
