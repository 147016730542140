import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import {
  updateAdminSupportQueue,
  createAdminSupportQueue,
  createAdminSupport,
  getAdminSupportQueue,
} from "../../actions/adminSupportActions";
import { getAllUsers } from "../../actions/adminDashActions";
import { getAccounts } from "../../actions/account/accountAdminActions";
import Grid from "@mui/material/Grid";
import InputBox from "../../components/InputBox";
import InputDateBox from "../../components/InputDateBox";
import SelectBox from "../../components/SelectBox";
import AdminAccountSearch from "../../components/AdminAccountSearch";
import Error from "../Error";
import Success from "../Success";
import CheckBox from "../../components/CheckBox";
import {
  formatDateTimeUtc,
  formatDateTimeToUtc,
} from "../../actions/momentActions";
import { LABELS as _LABELS } from "../../language";
import { validateQueue } from "./validate";

const LABELS = _LABELS.supportForm;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class QueueEditModal extends React.Component {
  state = {
    accounts: [],
    drivers: [],
    supUsers: [],
    selected: {},
  };

  componentDidMount() {
    const selected = this.props.selected || {};

    this.getSupportUsers();

    if (selected.accountId) {
      this.getQueueById(selected._id);
      this.getDrivers(selected.accountId);
    }
  }

  _getAccounts = async () => {
    try {
      const _accounts = await this.props.getAccounts({});
      this.setState({ accounts: _accounts });
    } catch (e) {
      console.log(e);
    }
  };

  getQueueById = async (_id) => {
    const _selected = await this.props.getAdminSupportQueue({
      id: _id,
    });
    this.setState({ selected: _selected });
  };

  getDrivers = async (accountId) => {
    const payload = {
      limit: 500,
      skip: 0,
      status: "ACTIVE",
      accountId: accountId,
    };

    const _drivers = await this.props.getAllUsers(payload);
    const drivers = _drivers.map((item) => {
      item.id = item.driver.driverId;
      return item;
    });
    this.setState({ drivers: drivers || [] });
  };

  getSupportUsers = async () => {
    const supUsers = await this.props.getAllUsers({
      skip: 0,
      limit: 500,
      searchSupport: "ON",
    });
    this.setState({ supUsers: supUsers || [] });
  };

  handleSubmit = async (values) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    if (values.cb && values.cbTm) {
      values.cbTm = this.props.formatDateTimeToUtc(values.cbTm);
    } else {
      values.cbTm = null;
    }

    if (values.callTm) {
      values.callTm = this.props.formatDateTimeToUtc(values.callTm);
    }

    const response = values._id
      ? await this.props.updateAdminSupportQueue(values)
      : await this.props.createAdminSupportQueue(values);

    if (response && response._id) {
      this.setState({
        successMessage: LABELS.successMessage,
        loading: false,
      });
      setTimeout(() => {
        this.props.handleAfterSave();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = LABELS.response;
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  addSupportTicket = async (values) => {
    let selected = this.state.selected || {};

    const driver = selected.driver || {};
    const assignedTo = selected.assignedTo || {};
    const payloadSupport = {
      caller: {
        name: driver.name,
        email: driver.email,
        phone: driver.phone,
      },
      cbInfo: {},
      assignedTo: {
        name: assignedTo.name,
        email: assignedTo.email,
      },
      app: "SE",
      accountId: selected.accountId,
      driverId: selected.driverId,
      accountName: selected.accountName,
      plan: selected.plan,
      product: selected.product,
      category: "SUPPORT",
      callType: "INBOUND",
      callStatus: selected.status,
      callTm: null,
      priority: selected.priority,
      title: selected.reason,
      detail: selected.reason,
      cb: "NO",
    };

    const responseSupport = await this.props.createAdminSupport(payloadSupport);

    if (responseSupport._id) {
      selected.supportId = responseSupport._id;
      selected.supportTicket = responseSupport.ticketNo;
      await this.handleSubmit(selected);
    } else {
      this.setState({
        errorMessage: "Not able add support ticket",
        loading: false,
      });
    }
  };

  render() {
    const { classes, constants, planName } = this.props;
    const accounts = this.state.accounts || [];
    const drivers = this.state.drivers || [];
    const supUsers = this.state.supUsers || [];
    const selectedId = _get(this, "props.selected._id");
    const selected = this.state.selected || {};
    const isSmallScreen = window.innerWidth < 400;
    const { errorMessage, successMessage, loading } = this.state;
    let initialValues = {
      status: "NEW",
      priority: "LOW",
      app: "SE",
      category: "SUPPORT",
      callType: "INBOUND",
      callTm: null,
      account: '',
      driverId: '',
      reason: '',
      ...selected,
    };

    if (selected && selected.callTm && selected.callTm) {
      initialValues.callTm = this.props.formatDateTimeUtc(selected.callTm);
    }
    if (selected && selected.cbTm && selected.cbTm) {
      initialValues.cbTm = this.props.formatDateTimeUtc(selected.cbTm);
    }

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"xl"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{selectedId ? `Update` : `Add`}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedId && !selected._id ? (
            <h2>{`${LABELS.PleaseWaitLoading}`}</h2>
          ) : (
            <Formik
              initialValues={initialValues}
              validate={validateQueue}
              onSubmit={this.handleSubmit}
              enableReinitialize={false}
            >
              {({ values, handleSubmit, setFieldValue, errors }) => {

                values.isCbDis = Boolean(values.cb);
                return (
                  <Form style={{ padding: "20px 10px" }}>
                    <Grid container spacing={3}>
                      {selected.supportTicket && (
                        <Grid md={12} item>
                          <h4>{`Support ticket# ${selected.supportTicket}`}</h4>
                        </Grid>
                      )}
                      {!selectedId && (
                        <AdminAccountSearch
                          md={3}
                          selValue={"_id"}
                          selLabel={"name"}
                          name="account"
                          label={`${LABELS.account}`}
                          items={accounts}
                          error={errors.accountId}
                          onChange={(e) => {
                            if (e && e._id) {
                              const plan = _get(
                                e,
                                "plan.plan.name",
                                "STANDARD"
                              );
                              const _aId = e._id;
                              const _aName = e.name;
                              this.getDrivers(_aId);
                              setFieldValue("accountId", _aId);
                              setFieldValue("accountName", _aName);
                              setFieldValue("plan", plan);
                            }
                          }}
                        />
                      )}
                      <SelectBox
                        md={3}
                        selValue={"id"}
                        selLabel={"firstName"}
                        selLabelTwo={"lastName"}
                        selLabelThree={"status"}
                        name="driverId"
                        label={LABELS.driver}
                        items={drivers}
                        onChange={(e) => {
                          const _dId = e.target.value;
                          const _driver = drivers.find(
                            (item) => item.id === _dId
                          );
                          const _d = {
                            name: `${_driver.firstName} ${_driver.lastName}`,
                            email: _driver.email,
                            phone: _driver.mobile,
                          };
                          setFieldValue("driver", _d);
                        }}
                      />
                      <SelectBox
                        md={3}
                        name="reason"
                        label={`${LABELS.reason}`}
                        items={constants.QUEUE_REASON}
                      />
                      <InputBox
                        md={3}
                        name="driver.phone"
                        label={`${LABELS.driverPhone}`}
                      />
                      <InputBox
                        md={3}
                        name="driver.email"
                        label={`${LABELS.driveremail}`}
                      />
                      <SelectBox
                        md={2}
                        name="status"
                        label={`${LABELS.status}`}
                        items={constants.QUEUE_STATUS}
                      />
                      <SelectBox
                        md={2}
                        name="priority"
                        label={`${LABELS.priority}`}
                        items={constants.QUEUE_PRORIRY}
                      />
                      <CheckBox
                        md={2}
                        name="cb"
                        label={`${LABELS.callback}`}
                        paddingTop={40}
                      />
                      {values.isCbDis && (
                        <InputDateBox
                          md={3}
                          type={"datetime-local"}
                          name="cbTm"
                          label={`${LABELS.callback_time}`}
                        />
                      )}

                      <SelectBox
                        md={values.isCbDis ? 3 : 6}
                        selValue={"id"}
                        selLabel={"firstName"}
                        selLabelTwo={"lastName"}
                        selLabelThree={"status"}
                        name="assignedTo.id"
                        label={`${LABELS.assignedto}`}
                        items={supUsers}
                        onChange={(e) => {
                          const _uId = e.target.value;
                          const _sel =
                            supUsers.find((item) => item.id === _uId) || {};

                          setFieldValue(
                            "assignedTo.name",
                            `${_sel.firstName} ${_sel.lastName}`
                          );
                          setFieldValue("assignedTo.email", _sel.email);
                          setFieldValue("assignedTo.id", _uId);
                        }}
                      />
                    </Grid>
                    {successMessage && <Success message={successMessage} />}
                    {errorMessage && <Error message={errorMessage} />}
                    <DialogActions className={classes.DialogActions}>
                      {!selected.supportId &&
                        (values.status === "COMPLETE" ||
                          values.status === "CALLBACK") && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.addSupportTicket()}
                          >
                            {`${LABELS.addSupportTicket}`}
                          </Button>
                        )}
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                      >
                        {loading ? LABELS.saving : LABELS.save}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={this.props.handleClose}
                      >
                        {LABELS.close}
                      </Button>
                    </DialogActions>
                  </Form>
                );
              }}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: _get(state, "authData.profile", {}),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    planName: _get(state, "authData.profile.plan.plan.name", "STANDARD"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccounts,
      getAllUsers,
      updateAdminSupportQueue,
      createAdminSupportQueue,
      formatDateTimeUtc,
      formatDateTimeToUtc,
      createAdminSupport,
      getAdminSupportQueue,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(QueueEditModal))
);
