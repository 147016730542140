export const LABELS = {
  DASHCAMERA: {
    header: "एकीकृत वीडियो यहाँ है।",
    header2: "ओस्रिट डैशकैम आपको कठोर ड्राइविंग घटनाओं के दृश्य संदर्भ प्रदान करके ड्राइवर व्यवहार में सुधार करने और आपके व्यवसाय के जोखिमों को कम करने में मदद करता है।",
    header3: "अधिक जानने के लिए, कृपया पर जाएं",
    header3Link: "www.truckdashcamera.com",
  },
  OSRIT: {
    header: "क्लाउड ट्रकिंग सॉफ्टवेयर | टीएमएस।",
    header2: "ओस्रिट क्लाउड सॉफ्टवेयर आपको एक ही स्थान से एफटीएल या एलटीएल लोड, ग्राहक, दलाल, ड्राइवर, पेरोल, चालान आदि को आसानी से भेजने में मदद करता है!",
    header3: "अधिक जानने के लिए, कृपया पर जाएं",
    header3Link: "www.osrit.com",
  },
  SIGN_IN_FORM: {
    email: "ईमेल",
    password: "पासवर्ड",
    logInBtn: "लॉग इन करें",
    logInLoading: "लोड हो रहा है...",
    forgetPwd: "पासवर्ड भूल गए",
    required: "आवश्यक",
    inValidEmail: "अवैध ईमेल पता",
    invalidPassword: "अवैध पासवर्ड",
    passwordNoMatch: "पासवर्ड मेल नहीं खाते",
    invalidEmailPwd: "अमान्य ईमेल या पासवर्ड"
  },
};
