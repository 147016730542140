import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import NotFoundTableRow from "../NotFoundTableRow";
import Chip from "@mui/material/Chip";
import ConfirmActionModal from "../ConfirmActionModal";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.settingPage;


const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    tableHead: {
        // padding: 5,
        // minWidth: 130
    },
    tableRowTitle: {
        display: "inline-block"
        // width: 95
    },
    capitalize: {
        textTransform: "capitalize"
    },
    saveIcon: {
        color: "#009be5",
        cursor: "pointer"
    },
    detailTitle: {
        display: "inline-block",
        minWidth: 100,
        lineHeight: 1.5
    }
});

class ReduxTableRow extends React.Component {
    renderViolations = item => {
        const violations = item.violations || [];

        if (violations && Array.isArray(violations) && violations.length > 0) {
            return violations.map((violation, idx) => (
                <div
                    key={`${violation.statusId}-${idx}`}
                    style={{ marginBottom: 2 }}
                >
                    <Chip
                        size="small"
                        label={violation.id}
                        color="secondary"
                        variant="outlined"
                        style={{ minWidth: 30 }}
                        onDelete={() => this.props.handleDelete(item, violation)}
                    />
                </div>
            ));
        } else {
            return null;
        }
    };

    render() {
        const { classes, item = {} } = this.props;
        return (
            <TableRow hover>
                <TableCell>{this.props.formatDate(item.logDate)}</TableCell>
                <TableCell>
                    <div className={classes.capitalize}>
                        {item.driver && (
                            <div>
                                {item.driver.firstName} {item.driver.lastName}
                            </div>
                        )}
                    </div>
                </TableCell>
                <TableCell>
                    <div className={classes.capitalize}>
                        {this.renderViolations(item)}
                    </div>
                </TableCell>
                <TableCell>
                    {item.offDutyHours && (
                        <div className={classes.capitalize}>
                            <span className={classes.detailTitle}>
                                Driving:
                            </span>
                            {item.drivingHours}
                        </div>
                    )}
                    {item.offDutyHours && (
                        <div className={classes.capitalize}>
                            <span className={classes.detailTitle}>
                                OffDuty:
                            </span>
                            {item.offDutyHours}
                        </div>
                    )}
                    {item.offDutyHours && (
                        <div className={classes.capitalize}>
                            <span className={classes.detailTitle}>
                                OnDutyND:
                            </span>
                            {item.onDutyNDHours}
                        </div>
                    )}
                    {item.offDutyHours && (
                        <div className={classes.capitalize}>
                            <span className={classes.detailTitle}>
                                Sleeper:
                            </span>
                            {item.sleeperHours}
                        </div>
                    )}
                </TableCell>
            </TableRow>
        );
    }
}

export class ReduxTable extends React.Component {
    state = {
        order: "asc"
    };

    handleChangePage = (event, page) => {
        const { limit } = this.props;
        this.props.handleChange({ page, limit });
    };

    handleChangeRowsPerPage = event => {
        const limit = event.target.value;
        const { page } = this.props;
        this.props.handleChange({ page, limit });
    };

    handleDelete = diary => {
        this.setState({
            isConfirmOpen: true,
            deleting: false,
            selected: diary
        });
    };

    onDelete = async () => {
        this.setState({ deleting: true });

        const selected = this.state.selected;
        const payload = {
            _id: selected._id,
            date: selected.date,
            driverId: selected.driverId,
            violations: []
        };

        await this.props.updateDriverDaily(payload);

        this.setState({
            deleting: false,
            isConfirmOpen: false,
            selected: null
        });

        const { page, limit } = this.props;
        await this.props.handleChange({ page, limit });
    };

    render() {
        const { classes, items = [], count = 1000 } = this.props;
        const { page, limit } = this.props;
        const { isConfirmOpen, selected, deleting } = this.state;

        return (
            <Paper className={classes.root}>

                {isConfirmOpen && (
                    <ConfirmActionModal
                        open={isConfirmOpen}
                        selected={selected}
                        loading={deleting}
                        handleClose={() =>
                            this.setState({
                                isConfirmOpen: false,
                                deleting: false,
                                selected: null
                            })
                        }
                        handleConfirm={this.onDelete}
                        label={"Delete Violations"}
                        message={
                            "Are you sure, you want to delete all violations"
                        }
                    />
                )}
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>{LABELS.driver}</TableCell>
                            <TableCell>{LABELS.type}</TableCell>
                            <TableCell>{LABELS.details}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(items) &&
                            items.map((item, i) => (
                                <ReduxTableRow
                                    item={item}
                                    key={i}
                                    classes={classes}
                                    formatDate={this.props.formatDate}
                                    formatTime={this.props.formatTime}
                                    handleDelete={this.handleDelete}

                                />
                            ))}
                    </TableBody>
                </Table>
                <NotFoundTableRow items={items} />
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                    component="div"
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

ReduxTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ReduxTable);
