import React from "react";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
// import Input from "../../components/Input";
import Error from "../Error";
import Success from "../Success";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  AlignCenter: {
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class ConfirmActionModal extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {}

  render() {
    const {
      label,
      message,
      loading,
      classes,
      btnLabel = "DELETE",
      btnLoading = "Deleting...",
    } = this.props;

    const { errorMessage, successMessage } = this.props;
    const isSmallScreen = window.innerWidth < 400;

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"md"}
        className={classes.wrapper}
        open={!!this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <Typography>{label}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} className={classes.AlignCenter}>
              <h2>{message}</h2>
            </Grid>
            {errorMessage || successMessage ? (
              <Grid item xs={12} sm={12} md={12}>
                <Error message={errorMessage} />
                <Success message={successMessage} />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={12} className={classes.AlignCenter}>
              <Button
                onClick={this.props.handleConfirm}
                color="error"
                autoFocus
                variant="contained"
                disabled={loading}
              >
                {loading ? btnLoading : btnLabel}
              </Button>
              <br />
              <Button
                onClick={this.props.handleClose}
                color="primary"
                autoFocus
              >
                CANCEL
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ConfirmActionModal);
