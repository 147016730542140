import React, { useState } from "react";
import _get from "lodash/get";
import { withStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import DutyStatus from "../../../components/DutyStatusCir";
import PersonIcon from "@mui/icons-material/Person";
import SpeedIcon from "@mui/icons-material/Speed";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import EvStation from "@mui/icons-material/EvStation";
import WarningIcon from "@mui/icons-material/Warning";
import VideocamIcon from "@mui/icons-material/Videocam";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HistoryIcon from "@mui/icons-material/History";
import ChartModal from "../StatusChart/ChartModal";
import CleanModal from "../../AdminUser/CleanModal";
import DashCamModal from "./DashCamModal";
import VehicleDetailModal from "./VehicleDetailModal";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { J1939_parse_string } from "../../../utils/dtcDecode";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: 0,
    },
    padding: "4px 12px 8px 12px",
  },
  paper: {
    padding: theme.spacing(1),
  },
  hr: {
    marginTop: "4px",
    padding: "0px",
    borderBottom: "0.1em solid grey",
    borderRadius: "5px",
  },
  listitems: {
    clear: "both",
    fontSize: "11px",
    margin: 0,
    marginBottom: "0.2rem",
  },
  icon: {
    verticalAlign: "bottom",
    fontSize: "18px !important",
    marginRight: 5,
    color: "black",
  },
  iconVideo: {
    marginBottom: 2,
    verticalAlign: "bottom",
    fontSize: "18px !important",
  },
  img: {
    verticalAlign: "baseline",
    marginRight: "0.4rem",
    width: "0.9rem",
    height: "0.9rem",
  },

  link: {
    textDecoration: "none",
    color: "black",
  },
  zoomicon: {
    verticalAlign: "bottom",
    fontSize: 18,
    width: "0.9rem",
    height: "0.9rem",
    marginTop: -10,
  },
  rTitle: {
    display: "inline-block",
    minWidth: 50,
  },
  agriExm: {
    textTransform: "uppercase",
    flexBasis: "25%",
    padding: "1px",
    backgroundColor: "grey",
    textAlign: "center",
    color: "white",
    display: "inline-block",
    minWidth: "40px",
    borderRadius: "6px",
    fontWeight: 500,
    border: "none",
    fontSize: "12px",
  },
  circle: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    padding: "1px",
    backgroundColor: "grey",
    textAlign: "center",
    color: "white",
    width: "32px",
    height: "32px",
    borderRadius: "16px",
    fontWeight: 500,
    border: "none",
    fontSize: "13px",
    cursor: "pointer",
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    padding: theme.typography.pxToRem(10),
  },
}))(Tooltip);

const MapListItems = (props) => {
  const [openChart, setOpenChart] = useState(false);
  const [openClean, setOpenClean] = useState(false);
  const [openDc, setOpenDc] = useState(false);
  const [openVehDetail, setOpenVehDetail] = useState(false);
  const [selDriver, setSelDriver] = useState();
  const [selDetail, setSelDetail] = useState();
  const classes = useStyles();
  const driver = props.driver || {};
  const coDriver = _get(props.item, "coDriver");
  const vehicle = props.vehicle || {};
  const item = props.item || {};
  const { accountBaseUrl, show } = props;
  const remaining = item.remaining;
  const isViolation = !!(remaining && remaining.violation);
  const isEmpty = Object.keys(remaining).length === 0;
  const showInfo = !(!props.rpm || props.rpm === "0");

  const color =
    item.eventCode === "DRIVING"
      ? "#1f9a07"
      : item.eventCode === "SLEEPER"
      ? "#FF7E0E"
      : item.eventCode === "ONDUTY_ND"
      ? "#0085EC"
      : "grey";
  const EC =
    item.eventType === "YARD_MOVES"
      ? " YM"
      : item.eventType === "PERSONAL_USE"
      ? "PC"
      : item.eventCode === "DRIVING"
      ? "D"
      : item.eventCode === "SLEEPER"
      ? "SB"
      : item.eventCode === "ONDUTY_ND"
      ? "ON"
      : "OFF";

  function onOpenClear(_driver) {
    if (props.adminKey) {
      setSelDriver(_driver);
      setOpenClean(true);
    }
  }

  function filterName(f, l) {
    let name = `${f} ${l}`;
    const array = name.split(" ");
    if (name.length > 16) {
      let lastName = array[array.length - 1];
      let firstName = f;
      if (firstName > 15) {
        firstName = array[0];
      }
      if (!lastName) {
        name = firstName;
      } else {
        name = `${firstName} ${lastName[0]}`;
      }
    }
    return name;
  }
  function filterLocation(l) {
    let name = "";
    const array = l.split(" ");
    if (l.length > 23) {
      array.forEach((e, i) => {
        if (i > 2) {
          name = `${name} ${e}`;
        }
      });
    } else {
      name = l;
    }
    return name.trim();
  }

  function onVehicleClick(_data) {
    setOpenVehDetail(true);
    setSelDetail(_data);
  }
  function onDcClick(_data) {
    setOpenDc(true);
    setSelDetail(_data);
  }

  const isCoDriver = coDriver && coDriver.id ? true : false;
  let errorCodes = props.faultCodes ? J1939_parse_string(props.faultCodes) : "";

  if (errorCodes && Array.isArray(errorCodes) && errorCodes.length < 1) {
    errorCodes = false;
  }

  return (
    <div className={classes.root}>
      {!isEmpty ? (
        <ChartModal
          open={openChart}
          handleClose={() => {
            setOpenChart(false);
          }}
          item={item}
          driver={driver}
          coDriver={coDriver}
          isViolation={isViolation}
          remaining={remaining}
        />
      ) : null}
      {openClean ? (
        <CleanModal
          open={openClean}
          handleClose={() => {
            setSelDriver(null);
            setOpenClean(false);
            props.getRealTime();
          }}
          driver={selDriver}
        />
      ) : null}
      {openVehDetail ? (
        <VehicleDetailModal
          open={openVehDetail}
          handleClose={() => {
            setOpenVehDetail(false);
            setSelDetail(null);
          }}
          data={selDetail}
        />
      ) : null}
      {openDc ? (
        <DashCamModal
          open={openDc}
          handleClose={() => {
            setOpenDc(false);
            setSelDetail(null);
          }}
          data={selDetail}
          getDcImages={props.getDcImages}
          getDcDetail={props.getDcDetail}
          formatDate={props.formatDate}
          formatTime={props.formatTime}
        />
      ) : null}
      <Grid container style={{ padding: 0 }}>
        <Grid item xs={12} sm={show ? 4 : 8} style={{ textAlign: "left" }}>
          <Typography
            variant="subtitle2"
            className={classes.listitems}
            style={{
              textTransform: "capitalize",
              width: "max-content",
            }}
          >
            {driver && (
              <Link
                to={`${accountBaseUrl}/status/diary/${item.driverId}`}
                className={classes.link}
                style={{ marginTop: "3px" }}
              >
                <HtmlTooltip
                  placement="top"
                  style={{ margin: 0, padding: 0, marginTop: -4 }}
                  title={
                    <React.Fragment>
                      <div>
                        <span className={classes.rTitle}>{`Co-Driver:`}</span>
                        <span
                          style={{
                            textTransform: "uppercase",
                          }}
                        >
                          {isCoDriver
                            ? ` ${coDriver.firstName} ${coDriver.lastName}`
                            : " N/A"}
                        </span>
                      </div>
                    </React.Fragment>
                  }
                >
                  <IconButton>
                    <PersonIcon
                      className={classes.icon}
                      color={isCoDriver ? "primary" : ""}
                    />
                  </IconButton>
                </HtmlTooltip>
                {filterName(driver.firstName, driver.lastName)}
              </Link>
            )}
          </Typography>

          <Typography variant="subtitle2" className={classes.listitems}>
            {vehicle.name ? (
              <div>
                {errorCodes ? (
                  <WarningIcon
                    color="error"
                    fontSize="small"
                    className={classes.iconVideo}
                    onClick={() => onVehicleClick(props)}
                  />
                ) : (
                  <LocalShippingIcon
                    fontSize="small"
                    className={classes.iconVideo}
                    onClick={() => onVehicleClick(props)}
                  />
                )}
                <span>{` ${vehicle.name} `}</span>
                {driver.dcId && (
                  <VideocamIcon
                    fontSize="small"
                    className={classes.iconVideo}
                    onClick={() => onDcClick(props)}
                  />
                )}
              </div>
            ) : (
              ""
            )}
          </Typography>

          <Typography variant="subtitle2" className={classes.listitems}>
            <p className={classes.listitems}>
              <SpeedIcon className={classes.icon} />
              {showInfo ? `${props.speed} M/hr` : "N/A"}
            </p>
          </Typography>
          {props.fuel !== 0 && props.fuel <= 20 ? (
            <Typography variant="subtitle2" className={classes.listitems}>
              <EvStation
                className={classes.icon}
                color={"secondary"}
                aria-label="Fuel"
              />
              <span style={{ color: "red" }}>Low fuel</span>
            </Typography>
          ) : null}
        </Grid>
        {show ? (
          <Grid item xs={12} sm={8}>
            <div className="dutycircle">
              <div
                className={classes.listitems}
                style={{
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  style={{ padding: 2 }}
                  color={isViolation ? "secondary" : "primary"}
                  aria-label="History"
                  onClick={() => setOpenChart(true)}
                >
                  <HistoryIcon />
                </IconButton>
                <span onClick={() => onOpenClear(item)}>
                  {remaining.exception === "3" ? (
                    <span className={classes.agriExm}>AG</span>
                  ) : (
                    <>
                      {" "}
                      {item.eventCode ? (
                        <DutyStatus
                          status={item}
                          eventType={item.eventType}
                          eventCode={item.eventCode}
                          annotation={item.annotation}
                          eventSubType={item.eventSubType}
                          isError={isViolation}
                        />
                      ) : (
                        "NO STATUS"
                      )}
                    </>
                  )}
                </span>
              </div>
            </div>

            <p
              className={classes.listitems}
              style={{ float: "right" }}
            >{`${props.date} @ ${props.time}`}</p>
            <p
              className={classes.listitems}
              style={{
                float: "right",
                marginLeft: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                props.popUpOpen(props.item.uqId);
              }}
            >
              {props.location ? (
                <>
                  {filterLocation(props.location)}{" "}
                  <ZoomInIcon className={classes.zoomicon} />
                </>
              ) : null}
            </p>
          </Grid>
        ) : (
          <Grid item xs={12} sm={show ? 8 : 4} style={{ textAlign: "right" }}>
            <span
              onClick={() => {
                setOpenChart(true);
              }}
            >
              {remaining.exception === "3" ? (
                <span className={classes.circle}>AG</span>
              ) : (
                <span
                  className={classes.circle}
                  style={{ backgroundColor: color }}
                >
                  {EC}
                </span>
              )}
            </span>
          </Grid>
        )}
      </Grid>
      <div className={classes.hr} />
    </div>
  );
};
export default MapListItems;
