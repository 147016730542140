export const LABELS={
    addsupportcase:"ADD SUPPORT CASE",
    addShipping:"ADD SHIPPING",
    addtodo:"ADD TODO",
    
    account: "Account",
    driver: "Driver",
    category:"Category",
    type:"Type",
    product:"Product",

    priority:"Priority",
    status:"Status",
    time:"Time",
    assignedto:"Assigned to",
    assignee_email:"Assignee email",
    description:"Description",


    issue:"Issue",
    detail:"Detail",
    trackingUrl:"TrackingUrl",

    Solution:"Solution",

    caller_name:"Caller name",
    caller_email:"Caller email",
    caller_phone:"Caller Phone",


    callback:"Callback",
    callback_time:"CallBack Time",
    name:"Name",
    email:"Email",
    phone:"Phone",
    saving:"Saving...",
    save:"SAVE",
    closed:"CLOSED",
    search:"Search",
    clear:"Clear",
    new:"NEW",
    close:"CLOSE",



    Info:"Info",
    Caller:"Caller",
    supportPlan:"SUPPORT PLANS",
    supportticket:"Support ticket",
    reason:"Reason",
    driverPhone:"Driver phone",
    driveremail:"Driver email",
    addSupportTicket:"Add Support Ticket",
    

    

    PleaseWaitLoading:"Please wait, loading....",
    successMessage: "Support case is saved successfully",
    response :"Something went wrong, please try after some time.",

    rowsperpage: "Rows per page",
    

    

    
    

   

}