import React from "react";
import _get from "lodash/get";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { J1939_parse_string } from "../../../utils/dtcDecode";
import { Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  AlignCenter: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
  },
  AlignBtn: {
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  agriExm: {
    border: "1px solid grey",
    textTransform: "uppercase",
    flexBasis: "25%",
    flexShrink: 0,
    padding: 4,
    backgroundColor: "white",
    textAlign: "center",
    color: "black",
    display: "inline-block",
    width: 110,
    borderRadius: 5,
    fontWeight: 500,
  },
});

export class VehicleDetailModal extends React.Component {
  state = {
    loading: false,
    message: "",
  };

  handleClean = async () => { };

  render() {
    const { classes } = this.props;
    const telemetrics = this.props.data || {};
    // const loading = this.state.loading;
    const isSmallScreen = window.innerWidth < 500;
    const coDriver = _get(this, "props.data.item.coDriver", {});
    const { handleClose } = this.props;
    // console.log("coDriver__", coDriver)

    let errorCodes = [];
    const faultCodes = telemetrics.faultCodes;

    if (faultCodes) {
      errorCodes = J1939_parse_string(faultCodes);
      // errorCodes = __errorCodes.map((item) => {
      //   return `SPN ${item.spn} ${item.fmi} ${item.cm} ${item.oc}`;
      // });
    }

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"xs"}
        className={classes.wrapper}
        open={!!this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <Typography>{"Vehicle Info"}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {/* <DialogContent>
          <Grid container>
            {coDriver && coDriver.firstName && (
              <Grid item xs={4} sm={4} md={3}>
                <span className={classes.rTitle}>Codriver :</span>
              </Grid>
            )}
            {coDriver && coDriver.firstName && (
              <Grid item xs={8} sm={8} md={9}>
                {coDriver.firstName} {coDriver.lastName}
              </Grid>
            )}
            <Grid item xs={4} sm={4} md={3}>
              <span className={classes.rTitle}>Speed :</span>
            </Grid>
            <Grid item xs={8} sm={8} md={9}>
              {telemetrics.speed} M/hr
            </Grid>
            <Grid item xs={4} sm={4} md={3}>
              <span className={classes.rTitle}>Rpm :</span>
            </Grid>
            <Grid item xs={8} sm={8} md={9}>
              {telemetrics.rpm}
            </Grid>

            {telemetrics.fuel ? (
              <>
                <Grid item xs={4} sm={4} md={3}>
                  <span className={classes.rTitle}>Fuel :</span>
                </Grid>
                <Grid item xs={8} sm={8} md={9}>
                  {telemetrics.fuel} %
                </Grid>
              </>
            ) : null}

            {errorCodes &&
            Array.isArray(errorCodes) &&
            errorCodes.length > 0 ? (
              <>
                <Grid item xs={4} sm={4} md={3}>
                  <span className={classes.rTitle}>Fault Codes :</span>
                </Grid>
                <Grid item xs={8} sm={8} md={9}>
                  {errorCodes.map((item, idx) => {
                    // console.log("item ", item.spn);
                    if (item.spn) {
                      return (
                        <div key={idx}>
                          <span
                            style={{ minWidth: 125, display: "inline-block" }}
                          >{`SPN ${item.spn} ${item.fmi} ${item.cm} ${item.oc}`}</span>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Grid>
              </>
            ) : null}

             <Grid item xs={4} sm={4} md={3}>
              <span className={classes.rTitle}>Gear :</span>
            </Grid>
            <Grid item xs={8} sm={8} md={9}>
              {telemetrics.rpm}
            </Grid>
            <Grid item xs={4} sm={4} md={3}>
              <span className={classes.rTitle}>coolant :</span>
            </Grid>
            <Grid item xs={8} sm={8} md={9}>
              {telemetrics.rpm}
            </Grid>

            <Grid item xs={4} sm={4} md={3}>
              <span className={classes.rTitle}>odometer :</span>
            </Grid>
            <Grid item xs={8} sm={8} md={9}>
              {telemetrics.rpm}
            </Grid>

            <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
              <Button
                size="small"
                onClick={this.props.handleClose}
                color="primary"
              >
                CLOSE
              </Button>
            </Grid>
          </Grid>
        </DialogContent> */}

        <DialogContent>
          <Table>

            <TableBody>

              {coDriver && coDriver.firstName && (
                <TableRow>
                  <TableCell className={classes.rTitle}>Codriver :</TableCell>
                  <TableCell>{coDriver.firstName} {coDriver.lastName}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell className={classes.rTitle}>Speed :</TableCell>
                <TableCell>{telemetrics.speed} M/hr</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.rTitle}>Rpm :</TableCell>
                <TableCell>{telemetrics.rpm}</TableCell>
              </TableRow>
              {telemetrics.fuel ? (
                <TableRow>
                  <TableCell className={classes.rTitle}>Fuel :</TableCell>
                  <TableCell>{telemetrics.fuel} %</TableCell>
                </TableRow>
              ) : null}
              {errorCodes && Array.isArray(errorCodes) && errorCodes.length > 0 ? (
                <TableRow>
                  <TableCell className={classes.rTitle}>Fault Codes :</TableCell>
                  <TableCell>
                    {errorCodes.map((item, idx) => {
                      if (item.spn) {
                        return (
                          <div key={idx}>
                            <span style={{ minWidth: 125, display: "inline-block" }}>
                              {`SPN ${item.spn} ${item.fmi} ${item.cm} ${item.oc}`}
                            </span>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </TableCell>
                </TableRow>
              ) : null}
              {telemetrics.gear > 0 && (
                <TableRow>
                  <TableCell className={classes.rTitle}>Gear :</TableCell>
                  <TableCell>{telemetrics.gear}</TableCell>
                </TableRow>
              )}
              {telemetrics.coolant > 0 && (
                <TableRow>
                  <TableCell className={classes.rTitle}>Coolant :</TableCell>
                  <TableCell>{telemetrics.coolant}</TableCell>
                </TableRow>
              )}
              {telemetrics.odometerMi > 0 && (
                <TableRow>
                  <TableCell className={classes.rTitle}>Odometer :</TableCell>
                  <TableCell>{telemetrics.odometerMi.toFixed(2)}</TableCell>
                </TableRow>
              )}
              {telemetrics.engineHours > 0 && (
                <TableRow>
                  <TableCell className={classes.rTitle}>EngineHours :</TableCell>
                  <TableCell>{telemetrics.engineHours}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Grid container justifyContent="center">
            <Grid item xs={12} style={{ textAlign: "center",marginTop:"5px" }}>
              <Button size="small" onClick={handleClose} color="primary">
                CLOSE
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withRouter(VehicleDetailModal));
