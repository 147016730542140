export const LABELS = {
  dashboard: "डैशबोर्ड",
  overview: "अवलोकन",
  accounts: "खाते",
  support: "सहायता",
  queue: "पंक्ति",
  shipping: "शिपिंग",
  todo: "टू-डू",
  leads: "लीड्स",
  fmcsa: "एफएमसीएसए",
  audit: "लेखापरीक्षा",
  users: "उपयोगकर्ता",
  settings: "सेटिंग्स",
  reseller: "पुनर्विक्रेता",
  dailyLogs: "दैनिक लॉग्स",
  dvir: "डीवीआईआर",
  dutyStatus: "कर्तव्य स्थिति",
  tracking: "ट्रैकिंग",
  unidentified: "अज्ञात",
  malfunctions: "दोष",
  violations: "उल्लंघन",
  notifications: "सूचनाएं",
  document: "दस्तावेज़",
  logEdits: "लॉग संपादन",
  liveShare: "लाइव शेयर",
  stateMileage: "राज्य माइलेज",
  reports: "रिपोर्ट",
  fleetmanager: "फ्लीट मैनेजर",

  carrier: "वाहक",
  terminal: "टर्मिनल",
  equipment: "उपकरण",
  editReason: "संपादन कारण",
  profile: "प्रोफाइल",
  api: "एपीआई",
  alerts: "चेतावनी",
};