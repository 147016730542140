export const LABELS={

    addCarrier:"AÃ‘ADIR TRANSPORTISTA",
    editCarrier:"EDITAR TRANSPORTADOR",
    edit_Terminal:"EDITAR TERMINAL",
    add_Terminal:"AÃ‘ADIR TERMINAL",
    addAccount:"AÃ‘ADIR CUENTA",
    


    editEquipment:"EDITAR EQUIPO",
    addEquipment:"AÃ‘ADIR EQUIPO",


   editTrailer:"EDITAR TRÃILER",
   addTrailer:"AÃ‘ADIR TRÃILER",

   editReasons:"EDITAR RAZONES",

   logingUsers:"Usuarios de inicio de sesión",
   supportCentre:"Centro de Apoyo",

   addUser:"AGREGAR USUARIO",
   editUser:"EDITAR USUARIO",
   driver_details:"DETALLES DEL CONDUCTOR",
   update:"ACTUALIZAR",
   addApi_key:"AÃ‘ADIR CLAVE API",


    name:"Nombre",
    dot:"PUNTO",
    timezone:"Zona horaria",
    country:"PaÃ­s",
    address_line1:"DirecciÃ³n LÃ­nea 1",
    suit_bldg:"Traje.,edificio",
    city:"Ciudad",
    state:"Estado",
    zip:"Zip",
    contactName:"Nombre de contacto",
    email:"Correo electrÃ³nico",
    phone:"TelÃ©fono",
    role:"Role",
    dispatchName:"Nombre de envÃ­o",
    note:"Nota :",
   info:" La informaciÃ³n de envÃ­o solo es necesaria si desea enviar el envÃ­o al dispositivo ELD como notificaciones automÃ¡ticas.",
    save:"AHORRAR",
    saving:"Ahorro...",
    cancel:"CANCELAR",
    close:"CERCA",
    roles:"Roles",
    access:"Acceso",
    cargo:"Carga",
    hos:"HOS",
    dutyLogs:"REGISTROS DE DERECHOS",
    contact:"Contacto",
    required:"Requerido",
    speed:"Velocidad",
    billing: "Facturación",


    accountName:"Nombre de la cuenta",
   contactEmail:"Email de contacto",
   contactPhone:"TelÃ©fono de contacto",
   contactRole:"Rol de contacto",
   stripe:"Raya",
   lastActive:"Ãšltimo activo",



   driverId:"IdentificaciÃ³n del conductor",
   firstName:"Nombre de pila",
   lastName:"Apellido",
   mobile:"MÃ³vil",
   password:"ContraseÃ±a",
   licCountry:"Lic PaÃ­s",
   licState:"Estado de licencia",
   licNumber:"NÃºmero de licencia",
   cycleUsa:"Ciclo EE.UU.",
   cycleCA:"Ciclo CA",
   receiverMail:"Correo electrÃ³nico del destinatario",
   personal:"Personal",
   yardMoves:"Movimientos de jardÃ­n",
   allowEdit:"Permitir editar",
   tracking:"Seguimiento",
   exempt:"Exenta",
   exemptDriver:"Conductor exento",
   exemptAnnotation:"AnotaciÃ³n exenta",
   assignedVehicle:"VehÃ­culo asignado",
   enterMobileNumber:"Introduce el nÃºmero de mÃ³vil",
   currentVehicle:"VehÃ­culo actual",
   currentTrailer:"TrÃ¡iler actual",
   appHome_View:"Vista de inicio de la aplicaciÃ³n",
   firebase:"base de fuego",
   apiKey:"Clave API",
   scopes:"Alcances",
   message:"Mensaje",
   sendNow:"Enviar ahora",
   loading:"Cargando...",
   sendVia_whatsapp:"enviar por whatsapp",
   send_sms:"ENVIAR SMS",



   prefix:"Prefijo",
   state_mileage_report:"Informe estatal de kilometraje",
   pdf:"PDF",
   details:"DETALLES",
   carrierName:"Nombre de la compaÃ±Ã­a",
   vehicleName:"Nombre del vehÃ­culo",
   miles:"Millas",
   mileage_miles:"Kilometraje (Millas)",
   quarter:"Cuarto",
   report:"Informe",
   select_vehicle:"Seleccionar vehÃ­culo",
   odometer:"CuentakilÃ³metros",



   No_vehicle_is_assignedTO_deiver:" NingÃºn vehÃ­culo estÃ¡ asignado a la conductora",
   please_set_current_vehicle:"Por favor configure el vehÃ­culo actual",


    status:"Estado",
    terminal:"Terminal",
    address:"DIRECCIÃ“N",
    title:"TÃ­tulo",
    body:"Cuerpo",
    time:"Tiempo",
    user:"Usuario",
    allUser:"Todos los usuarios",
    sendNotification:"Enviar notificaciÃ³n",
    send:"Enviar",
    syncAll:"SINCRONIZAR TODO",
    inspector:"Inspectora",
   condition:"CondiciÃ³n",
   start_end:"Inicio fin",
   view:"VISTA",
   date:"Fecha",
   _note:"Nota",
   _details:"Detalles",
   _view:"Vista",
   settings:"Ajustes",
   select:"Seleccionar",
   coordinates:"Coordenadas",
   event:"Evento",
   table:"MESA",
   map:"MAPA",
   trips:"Excursiones",
   superAdmin:"Súper administrador",
   supportAdmin:"Administrador de soporte",
   delete:"BORRAR",
    edit:"Editar",



   driver_vehicle_inspection_report:"INFORME DE INSPECCIÃ“N DEL VEHÃCULO DEL CONDUCTOR (DVIR)",
   AS_REQUIREDBY_THE_Dot_FEDERAL_MOTOR_CARRIER_SAFETY_REGULATIONS:"SEGÃšN LO REQUIERE EL D.O.T. REGLAMENTOS FEDERALES DE SEGURIDAD PARA TRANSPORTISTAS DE MOTORES",
   dvirNumber:"NÃºmero DVIR: -",
  start_inspection:"Iniciar inspecciÃ³n",
  endInspection:"Finalizar la inspecciÃ³n",
  inspection_type:"Tipo de inspecciÃ³n",
  inspection_Area:"Ãreas de inspecciÃ³n",
  I_declare_that_this_asset_has_been_inspected_in_accordance_with_the_applicable_requirements:"Declaro que este activo ha sido inspeccionado de acuerdo con los requisitos aplicables.",
  driver_signature:"Firma del conductor",



   download:"Descargar",
   downloading:"Downloading...",
   not_able_to_download:"No se puede descargar",
   missingFile:"Archivo perdido",



   history:"HISTORIA",
   send_to_fmcsa:"ENVIAR A FMCSA",
   email_to_fmcsa:"ENVIAR UN CORREO ELECTRÃ“NICO A FMCSA",
   SelectDriver:"Seleccionar controlador",
   fromDate:"Partir de la fecha",
   toDate:"Hasta la fecha",
   comments:"Comentarios",
   fmcsa_data_transfer:"Transferencia de datos FMCSA (Pasos para transferir datos)",
   selectDateRange:"Seleccionar rango de fechas",
   enterComment:"Introducir comentario",
   sendDataTo_fmcsa:"Haga clic en \"Enviar a FMCSA\" para transferir datos a FMCSA",


    startTime:"Hora de inicio",
   endTime:"Hora de finalizaciÃ³n",
   duration:"DuraciÃ³n",
   recordStatus:"Estado de registro",
   recordOrigin:"Origen del registro",
   location:"UbicaciÃ³n",
   assign:"Asignar",
   driver:"Conductor",



   diagostic$Malfunction:"DiagnÃ³stico/mal funcionamiento",
   eventCode:"CÃ³digo de evento",
   eventTime:"Hora del evento",



    showall:"Mostrar todo",
    vehicle:"VEHÃCULO",
    trailer:"REMOLQUE",
    search:"Buscar...",
    add:"AGREGAR",
    liveshare:"Compartir en vivo",
    create_ifta:"CREAR IFTA",
    no_data_found:"Â¡Datos no encontrados!",
    no_recordFound:"No se encuentran registros",


    regulationMode:"Modo de regulaciÃ³n",
    plate:"LÃ¡mina",
    eld:"VEJEZ",
    created_edited:"Creado/Editado",
    created:"Creado",
    edited:"Editado",


    rowsperpage: "Filas por pÃ¡gina",




//   <------ADD equipment Form------->

vin:"vino",
make:"Hacer",
model:"Modelo",
year:"AÃ±o",
fuel:"Combustible",
type:"Tipo",
licPlate:"Placa Lic",
dvirForm:"Formulario DVIR",
gps_source:"Fuente GPS",
devicetype:"Tipo de dispositivo",
devicename:"Nombre del dispositivo",
macAddrerss:"DirecciÃ³n MAC",
eld_serial:"Serie ELD",
eld_firmware:"Firmware ELD",
eld_BleVersion:"ELD VersiÃ³n Ble",
connectionType:"Tipo de conecciÃ³n",
dashcam:"cÃ¡mara de salpicadero",
odometer_offset:"CompensaciÃ³n del odÃ³metro",
active:"Activo",
codes:"CÃ³digos",
ifta:"IftÃ¡",
vbusConnection:"Se requiere conexiÃ³n VBUS para conducir",
carrier:"Transportador",
dvir :"Dvir",
licExpiry:"Caducidad de la licencia",
adminScope:"ÃMBITOS ADMINISTRATIVOS",


dashcameraId:"ID de la cÃ¡mara del tablero",
reseller:"Revendedores",
updateFrequency:"Frecuencia de actualizaciÃ³n",
bucket:"Balde",
theme:"Tema",
kioskpin:"quiosco",
hereAppId:"AquÃ­ ID de la aplicaciÃ³n",
hereApiKey:"AquÃ­ la clave API",
updateLocation:"Actualizar ubicaciÃ³n",
debug:"Depurar",
allLogs:"Todos los registros",
editLogs:"Editar registros",
documents:"Documentos",
notification:"Notificaciones",
addDvir:"AÃ±adir Dvir",
appSettings:"Ajustes de Aplicacion",
appAll_Log:"Todos los registros de la aplicaciÃ³n",
appAllLogsFmcsa:"AplicaciÃ³n Todos los registros Fmcsa",
estimatedWaitTime:"Tiempo de espera estimado",
minutes:"minuto(s)",




noteinfo:"NOTA: Una vez que agregue un vehÃ­culo nuevo, se cargarÃ¡ automÃ¡ticamente a su tarjeta registrada.",
ifta_info_note: "IFTA solo estÃ¡ disponible para camiones activos.",
ifta_info:"Si desea desactivar o eliminar el camiÃ³n, descargue el kilometraje estatal antes de desactivar la acciÃ³n.",
saved_Successfully:"Guardado exitosamente",
updateSuccessfully:"Actualizado exitosamente",
sendSuccessfully:"SMS se enviÃ³ correctamente",
successMessage_carrier:"La portadora se guarda con Ã©xito",
successMessage: "El trÃ¡iler se guardÃ³ correctamente",
successMessage_Equipment: "El equipo se guardÃ³ exitosamente.",
please_try_aftersometime:"IntÃ©ntalo despuÃ©s de un tiempo.",
successMessage_user:"El usuario se agrega con Ã©xito",
successMessage_Api_Key:"La clave Api se agregÃ³ correctamente",
response : "Algo saliÃ³ mal, intÃ©ntalo mÃ¡s tarde.",
errorMessage:"Algo saliÃ³ mal, intÃ©ntalo mÃ¡s tarde.",
editReasonUpdated:"Los motivos de ediciÃ³n se actualizan correctamente.",
logs_are_successfully_submitted:"Los registros se enviaron correctamente.",
user_is_updated_successfully:"El usuario se actualiza con Ã©xito.",
driver_is_cleaned_successfully:"El conductor se limpia con Ã©xito.",
account_is_updated_successfully:"La cuenta se actualizÃ³ correctamente",
Note_only_requiredforSetNewPassword:"Nota: SÃ³lo es necesario si desea establecer una nueva contraseÃ±a",
driver_is_required:"Se requiere conductor.",
from_date_is_required:"Se requiere fecha desde.",
to_date_is_required:"Se requiere hasta la fecha.",
comment_is_required:"Se requiere comentario.",
comment_is_invalid:"El comentario no es vÃ¡lido.",


   are_you_sure_WantTo_delete:"¿Estás segura, quieres eliminar dvir?",
    errorMassage:"Algo salió mal, inténtalo más tarde.",
    deletedsucessfully:"Eliminado con éxito.",
    deleting:"Eliminando..."

 }
