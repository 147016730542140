import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  getAdminSupportQueueCount,
  getAdminSupportQueue,
} from "../../actions/adminSupportActions";
import { formatDateTime } from "../../actions/momentActions";
import { getDuration } from "../../utils/momentHelpers";
import { isQueueWait } from "../../clientConfig";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import QueueEditModal from "./QueueEditModal";
import {LABELS as _LABELS} from "../../language"


const moment = require("moment-timezone");

const LABELS=_LABELS.adminPage;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function RenderItem(props) {
  let errorStyles = {};
  let focusStyles = {};
  const queue = props.queue || {};
  const assignedTo = queue.assignedTo || {};
  const app = queue.app || {};
  const diff = getDuration(moment(queue.created), moment(), true);
  const minDiff = moment().diff(moment(queue.created), "minutes");

  if (isQueueWait) {
    if (queue.status === "NEW") {
      if (queue.plan === "ENTERPRISE" && minDiff > 15) {
        errorStyles = { backgroundColor: "red", color: "white" };
      } else if (queue.plan === "ENTERPRISE" && minDiff > 10) {
        errorStyles = { backgroundColor: "#F39C12", color: "white" };
      }
      if (queue.plan === "STANDARD" && minDiff > 30) {
        errorStyles = { backgroundColor: "red", color: "white" };
      } else if (queue.plan === "STANDARD" && minDiff > 15) {
        errorStyles = { backgroundColor: "#F39C12", color: "white" };
      }
    } else if (queue.status === "IN_PROGRESS") {
      errorStyles = { backgroundColor: "#ecec13" };
    } else if (queue.status === "CALLBACK") {
      errorStyles = { backgroundColor: "#009be5", color: "white" };
    }
    if (queue.plan === "ENTERPRISE") {
      focusStyles = { fontWeight: 600 };
    }
  }

  return (
    <TableRow key={queue._id}>
      <TableCell component="th" scope="row" style={focusStyles}>
        {isQueueWait && <div>{`Wait: ${diff}`}</div>}
        {queue.accountName}
        <div>
          <Chip size="small" label={queue.plan} variant="outlined" />{" "}
          <Chip
            style={errorStyles}
            size="small"
            label={queue.status}
            variant="outlined"
          />
        </div>
      </TableCell>
      <TableCell align="right" style={focusStyles}>
        {queue.driver && <div>{queue.driver.name}</div>}
        {queue.driver && <div>Ph: {queue.driver.phone}</div>}
        {queue.driver && <div>{LABELS.email} : {queue.driver.email}</div>}
      </TableCell>

      <TableCell align="right" style={focusStyles}>
        <div>{`${LABELS.reason}: ${queue.reason}`}</div>
        {app.app && <div>{`${LABELS.App}: ${app.app} (${app.version})`}</div>}
        {app.device && <div>{`${LABELS.Device}: ${app.device} (${app.os})`}</div>}
        {props.formatDateTime(queue.created)}
      </TableCell>
      <TableCell align="right">
        {queue.supportId ? (
          <span>{`Ticket# ${queue.supportTicket}`}</span>
        ) : (
          <Chip label="✘" style={{ color: "red" }} />
        )}
      </TableCell>
      <TableCell align="right">
        {assignedTo.name && <div>{assignedTo.name}</div>}
        {assignedTo.email && <div>{assignedTo.email}</div>}
        {queue.cb && <div>Cb: {props.formatDateTime(queue.cbTm)}</div>}
      </TableCell>
      <TableCell align="right">
        <IconButton
          size="small"
          aria-label="update"
          color="secondary"
          onClick={() => props.onUpdateClick(queue)}
        >
          <EditIcon color="primary" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export class QueueDetail extends React.Component {
  state = {
    accounts: [],
    drivers: [],
    supUsers: [],
    onEdit: false,
  };

  componentDidMount() {
    this.getAdminSupportQueue();
  }

  getAdminSupportQueue = async () => {
    this.setState({ loading: true });
    let params = { skip: 0, limit: 500 };

    if (this.state.showAll) {
      params.status = "COMPLETE";
    }
    await this.props.getAdminSupportQueueCount({});
    const queues = await this.props.getAdminSupportQueue(params);
    this.setState({
      queues: queues || [],
      queuesOrg: queues || [],
      loading: false,
    });
  };

  filterQueues = (e) => {
    const query = e.target.value;
    const _queuesOrg = this.state.queuesOrg;
    if (query) {
      const _queues = _queuesOrg.filter((item) => {
        const accountName = item.accountName || "";
        const plan = item.plan || "";
        const reason = item.reason || "";
        const dName = (item.driver && item.driver.name) || "";
        return (
          accountName.toUpperCase().includes(query.toUpperCase()) ||
          plan.toUpperCase().includes(query.toUpperCase()) ||
          dName.toUpperCase().includes(query.toUpperCase()) ||
          reason.toUpperCase().includes(query.toUpperCase())
        );
      });
      this.setState({
        queues: _queues,
      });
    } else {
      this.setState({
        queues: _queuesOrg,
      });
    }
  };

  addQueue = async () => {
    this.setState({ onEdit: true, selQueue: null });
  };

  switchChange = () => {
    const { showAll } = this.state;
    this.setState({ showAll: !showAll }, () => {
      this.getAdminSupportQueue();
    });
  };

  render() {
    const { onEdit, selQueue, showAll } = this.state;
    const { loading } = this.state;
    const queues = this.state.queues || [];

    return (
      <div style={{ padding: 10 }}>
        {onEdit && (
          <QueueEditModal
            open={onEdit}
            selected={selQueue}
            handleClose={() => {
              this.setState({ onEdit: false });
            }}
            handleAfterSave={() => {
              this.setState({ onEdit: false });
              this.getAdminSupportQueue();
            }}
          />
        )}
        <TableContainer component={Paper}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showAll}
                    onChange={this.switchChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={`${LABELS.all}`}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" component="h5">
                {`${LABELS.queues}`}
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <TextField
                id="search"
                label={LABELS.search}
                variant="standard"
                onChange={this.filterQueues}
              />
              <Button
                onClick={this.getAdminSupportQueue}
                style={{ marginTop: 15 }}
              >
               {loading ? `${LABELS.loading}` : `${LABELS.refresh}`}
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={this.addQueue}
                style={{ marginTop: 15 }}
              >
                {"ADD"}
              </Button>
            </Grid>
          </Grid>

          <Table size="small" sx={{ minWidth: 650 }} aria-label="Queue table">
            <TableHead>
              <TableRow>
                <TableCell>{LABELS.carrier}</TableCell>
                <TableCell align="right">{LABELS.driver}</TableCell>
                <TableCell align="right">{LABELS.details}</TableCell>
                <TableCell align="right">{LABELS.support}</TableCell>
                <TableCell align="right">{LABELS.assigned}</TableCell>
                <TableCell align="right">{LABELS.actions}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {queues.map((queue) => (
                <RenderItem
                  queue={queue}
                  key={queue._id}
                  formatDateTime={this.props.formatDateTime}
                  onUpdateClick={(_selQueue) =>
                    this.setState({ onEdit: true, selQueue: _selQueue })
                  }
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: _get(state, "authData.profile.user", {}),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    queueCount: _get(state, "adminSupportData.queueCount"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAdminSupportQueueCount,
      getAdminSupportQueue,
      formatDateTime,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(QueueDetail))
);
