// import { settings } from "firebase/analytics";

export const LABELS = {
  accounts: "Accounts",
  drivers: "Drivers",
  equipments: "Equipments",

  allCompany: "All Company",
  company: "Company",
  dutyStatus: "Duty Status",
  connection: "Connection",
  connected: "Connected",
  disconnected: "Disconnected",
  offDuty: "Off Duty",
  onDuty: "On Duty",
  driving: "Driving",
  sleeping: "Sleeping",
  driverInfo: "Driver Info",
  name: "Name",
  status: "Status",
  violations: "Violations",
  date: "Date",
  eLDConnection: "ELD Connection",
  total: "Total",
  formsSignatures: "Forms & Signatures",
  time_period:"Time Period",
  today:"Today",
  yesterday:"Yesterday",
  thisWeek:"This Week",
  thisMonth:"This Month",
  sleeper:"Sleeper",
  remark:"Remark",
  break:"Break",
  OnDutyND:"OnDutyND",
  callback:"Callback",







  count: "Count",
  search: "Search",
  add: "Add",
  contact: "Contact",
  payment: "Payment",
  created: "Created",
  actions: "Actions",
  edit: "Edit",
  edited:"Edited",
  details: "Details",
  leads: "Leads",
  added:"Added",
  saving:"Saving...",
  settings:"Settings",

  account: "Account",
  driver: "Driver",
  clear: "Clear",
  new: "New",
  info: "Info",
  caller: "Caller",
  detail: "Detail",
  other: "Other",
  clean:"CLEAN",
  delete:"Delete",
  support:"Support",
  assigned:"Assigned",

  provider: "Provider",
  carrier: "Carrier",
  shipdate: "Ship date",
  tracking: "Tracking",
  addedby: "Added by",
  role:"Role",
  terminal:"Terminal",
  dispatch:"Dispatch",
  address:"Address",
  link:"Link",
  expiry:"Expiry",
  start_expiry_date:"Start Expiry Date",
  end_expiry_date:"End Expiry Date",

  commingSoon: "Comming Soon",

  startDate: "Start Date",
  endDate: "End Date",
  logs: "Logs",
  viewLogs: "View Logs",
  rowsperpage: "Rows per page",
  logIn: "LogIn",

  email: "Email",
  error: "Error",
  time: "Time", 
  phone:"Phone",
  reason:"Reason",
  App:"App",
  Device:"Device",


  searchbyemailorname: "Search by email or name",
  supportUsers: "Support Users",
  norecordsarefound: "No records are found",
  selectVehicle:"Select vehicle",

  syncRealTime: "SYNC REALTIME",
  syncingRealTime:"SYNCING REALTIME",

  reseller: "Reseller",
  billing: "Billing",
  overdues: "Over dues",
  missingstripe: "Missing stripe",
  notes: "Notes",

  annual: "Annually",
  monthly: "Monthly",
  enabled: "Enabled",
  disabled: "Disabled",
  sync: "Sync",
  syncing:"Syncing...",
  activate: "Activate",
  cancel: "Cancel",
  confirm: "Confirm",
  pleaseConfirm: "Please confirm, if you want to ",

  deactivate: "Deactivate",
  activeManagers: "Active Managers",
  inactiveManagers: "Inactive Managers",
  activeDrivers: "Active Drivers",
  inactiveDrivers: "Inactive Drivers",
  allowedVehicles: "Allowed Vehicles",
  activeVehicles: "Active Vehicles",
  inactiveVehicles: "Inactive Vehicles",
  plan: "Plan",
  planInfo: "Plan Info",
  apiAccess: "Api Access:",
  dues: "Dues",
  noduesfound: "No dues found",
  iftaAccess: "Ifta Access",
  addUser: "Managers",



 standard:"Standard",
 premium:"Premium",
 enterprise:"Enterprise",


  editplan:"EDIT PLAN",
  allowedVehicle:"Allowed Vehicle",
  currentVehicle:"Current Vehicle",
  Name:"Name",
  ifta:"IFTA",
  api:"API",
  truck:"Truck",
  note:'Note',
  addDue:"ADD DUE",
  save:"SAVE",
  close:"CLOSE",
  addUsers:"ADD USER",
  editUser:'EDIT USER',
  edit_User:"Edit User",
  managers:"Managers",

  callqueues:"Call queues",
  callInQueue:"calls in queue.",
  loading:"Loading...",
  refresh:"Refresh",
  view:"View",
  signOut:"Sign Out",
  AllAccounts:"All Accounts",
  CustomerSupportPackage:"Customer Support Package",
  queues:"Queues",
  all:"All",

  invoices:"Invoices",

  allEquipments:"All equipments",
  allStates:"All States",

  shippingSaveSuccessfully:"Shipping is saved successfully",
  planIs_updated_successfully:"Plan is updated successfully",
  errorMasage_tryafterSometime:"Something went wrong, please try after some time.",


};
