export const LABELS={
    addsupportcase:"AJOUTER UN CAS DE SUPPORT",
    addShipping:"AJOUTER L'EXPÉDITION",
    addtodo:"AJOUTER UNE TÊTE",
    
    account: "Compte",
    driver: "Conducteur",
    category:"Catégorie",
    type:"Taper",
    product:"Produit",

    priority:"Priorité",
    status:"Statut",
    time:"Temps",
    assignedto:"Assigné à",
    assignee_email:"E-mail du destinataire",
    description:"Description",


    issue:"Problème",
    detail:"Détail",
    trackingUrl:"URL de suivi",

    Solution:"Solution",

    caller_name:"Nom de l'appelant",
    caller_email:"E-mail de l'appelant",
    caller_phone:"Téléphone de l'appelant",


    callback:"Rappeler",
    callback_time:"Temps de rappel",
    name:"Nom",
    email:"E-mail",
    phone:"Téléphone",
    saving:"Économie...",
    save:"SAUVEGARDER",
    closed:"FERMÉ",
    search:"Recherche",
    clear:"Clair",
    new:"NOUVEAU",
    close:"FERMER",



    Info:"Info",
    Caller:"Votre interlocuteur",
    supportPlan:"RÉGIMES DE SOUTIEN",
    supportticket:"Billet d'assistance",
    reason:"Raison",
    driverPhone:"Téléphone du conducteur",
    driveremail:"E-mail du chauffeur",
    addSupportTicket:"Ajouter un ticket d'assistance",
    

    

    PleaseWaitLoading:"Veuillez patienter, chargement....",
    successMessage: "Le dossier d'assistance a été enregistré avec succès",
    response :"Quelque chose s'est mal passé, veuillez réessayer après un certain temps.",

    rowsperpage: "Lignes par page",
    

    

    
    

   

}