export const LABELS = {
  dashboard: "Dashboard",
  overview: "Overview",
  accounts: "Accounts",
  support: "Support",
  queue:"Queue",
  shipping: "Shipping",
  todo: "Todo",
  leads: "Leads",
  fmcsa: "Fmcsa",
  audit: "Audit",
  users: "Users",
  settings: "Settings",
  reseller: "Reseller",
  dailyLogs: "Daily Logs",
  dvir: "Dvir",
  dutyStatus: "Duty Status",
  tracking: "Tracking",
  unidentified: "Unidentified",
  malfunctions: "Malfunctions",
  violations: "Violations",
  notifications: "Notifications",
  document: "Document",
  logEdits: "Log Edits",
  liveShare: "Live share",
  stateMileage: "State Mileage",
  reports: "Reports",
  fleetmanager: "Fleet manager",

  carrier: "Carrier",
  terminal: "Terminal",
  equipment: "Equipment",
  editReason: "Edit Reason",
  profile: "Profile",
  api: "Api",
  alerts: "Alerts",
};
