import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./adminWhatsappActionTypes";

export const setChats = (value) => ({
  type: TYPES.CHATS,
  value,
});

export function getChats(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.WA_URL");

    return axios({
      url: `${BASE_URL}/chat`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", []);
        dispatch(setChats(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function replyChat(data = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.WA_URL");

    return axios({
      url: `${BASE_URL}/chat`,
      method: "post",
      data,
    })
      .then((response) => {
        const _response = _get(response, "data");
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getMessages(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.WA_URL");

    return axios({
      url: `${BASE_URL}/chat/message`,
      method: "get",
      params, // phoneFrom
    })
      .then((response) => {
        const _response = _get(response, "data", []);
        dispatch(setChats(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getMedia(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.WA_URL");

    return axios({
      url: `${BASE_URL}/chat/media`,
      method: "get",
      responseType: 'arraybuffer',
      params,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function chatRead(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.WA_URL");

    return axios({
      url: `${BASE_URL}/chat/read`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data");
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}
