export const LABELS = {
  dashboard: "Tableau de bord",
  overview: "Aperçu",
  accounts: "Comptes",
  support: "Soutien",
  queue:"File d'attente",
  shipping: "Expédition",
  todo: "Faire",
  leads: "Conduit",
  fmcsa: "Fmcsa",
  audit: "Audit",
  users: "Utilisateurs",
  settings: "Paramètres",
  reseller: "Revendeur",
  dailyLogs: "Journaux quotidiens",
  dvir: "Dvir",
  dutyStatus: "Statut de service",
  tracking: "Suivi",
  unidentified: "Unidentified",
  malfunctions: "Dysfonctionnements",
  violations: "Violations",
  notifications: "Notifications",
  document: "Document",
  logEdits: "Modifications du journal",
  liveShare: "Partage en direct",
  stateMileage: "Kilométrage de l'État",
  reports: "Rapports",
  fleetmanager: "Gestionnaire de flotte",

  carrier: "Transporteur",
  terminal: "Terminal",
  equipment: "Équipement",
  editReason: "Modifier le motif",
  profile: "Profil",
  api: "Api",
  alerts: "Alertes",
};
